import useQueryValue from "./useQueryValue";
import {useCallback, useMemo} from "react";

export default function useQueryValues(name: string, separator: string = '-'): [string[], (value: string[]) => void] {
    const [value, setValue] = useQueryValue(name);
    const values = useMemo(() => value.split(separator).filter(v => v), [value, separator]);

    const setValues = useCallback((values: string[]) => {
        setValue(values.join(separator));
    }, [setValue, separator])

    return [values, setValues];
}