import AuditEditState from "../types/AuditEditState";
import selectAllSteps from "./selectAllSteps";
import DisplayCondition from "../../api/types/DisplayCondition";
import {createSelector} from "reselect";
import stepsEqual from "../helpers/stepsEqual";

const selectSteps = createSelector(
    selectAllSteps,
    (state: AuditEditState) => state.answerGroups[state.auditId ?? -1] || {},
    (state: AuditEditState) => state.answers[state.auditId ?? -1] || {},
    (state: AuditEditState) => state.questionGroups,
    (state: AuditEditState) => state.questions,
    (steps, answerGroups, answers, questionGroups, questions) => {
        const meetsCondition = (condition: DisplayCondition): boolean => {
            const conditionQuestion = questions[condition.questionId];

            if (!conditionQuestion) return false;

            const questionGroup = questionGroups[conditionQuestion.questionGroupId];
            const answerGroup = answerGroups[conditionQuestion.questionGroupId];
            const conditionValue = answers[condition.questionId]?.value;

            if (questionGroup?.question && answerGroup?.value !== true) {
                return false;
            }

            // multiple answer not supported
            if (conditionQuestion.metricMultiple) {
                console.error(`MetricMultiple not supported`);
                return false;
            }

            switch (conditionQuestion.answerType) {
                case "radio":
                    return typeof conditionValue === "number" && condition.questionOptionIds.includes(conditionValue);
                case "checkbox":
                    const ids = Array.isArray(conditionValue) ? conditionValue : [];

                    if (condition.optionsSingleRequired) {
                        return condition.questionOptionIds.some(id => ids.includes(id))
                    } else {
                        return !condition.questionOptionIds.some(id => !ids.includes(id))
                    }
                case "numericfield":
                    if (typeof conditionValue !== "number" || !condition.numericValue) {
                        return false;
                    }

                    switch (condition.numericRelationalOperator) {
                        case "greater":
                            return conditionValue > condition.numericValue;
                        case "equal":
                            return conditionValue === condition.numericValue;
                        case "lesser":
                            return conditionValue < condition.numericValue;
                        default:
                            console.error(`Condition operator ${condition.numericRelationalOperator} not supported`);
                            return false;
                    }
                default:
                    console.error(`Answer type ${conditionQuestion.answerType} not supported`);
                    return false;
            }
        }

        return steps.filter(step => {
            if (step.name !== 'question') {
                return true;
            }

            const question = questions[step.questionId];
            if (!question) return false;

            const questionGroup = questionGroups[question.questionGroupId];
            const answerGroup = answerGroups[question.questionGroupId];

            if (questionGroup?.question && answerGroup?.value !== true) {
                return false;
            }

            if (!question?.displayConditions.length) {
                return true;
            }

            if (question.displayConditionAllRequired) {
                return !question.displayConditions.some(condition => !meetsCondition(condition));
            } else {
                return question.displayConditions.some(meetsCondition);
            }
        });
    },
    {memoizeOptions: {resultEqualityCheck: stepsEqual}}
);

export default selectSteps;