import createCachedSelector from "re-reselect";
import AuditNewState from "../types/AuditNewState";
import Filter from "../types/Filter";
import selectChecklistIdsFiltered from "./selectChecklistIdsFiltered";

const selectTopicIdsFiltered = createCachedSelector(
    (state: AuditNewState, filter: Filter) => selectChecklistIdsFiltered(state, filter),
    (state: AuditNewState) => state.checklists,
    (state: AuditNewState) => state.topicIds,
    (checklistIds, checklists, topicIds) => {
        return topicIds.filter(topicId => {
            return checklistIds.some(checklistId => {
                return checklists[checklistId]?.topicIds.includes(topicId);
            })
        });
    }
)({
    keySelector: (state, filter) => JSON.stringify(filter)
});

export default selectTopicIdsFiltered;