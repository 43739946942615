import AnswerGroup from "../../api/types/AnswerGroup";
import merge from "ts-deepmerge";
import undefinity from "../../common/helpers/undefinity";
import normalizeAnswer from "./normalizeAnswer";

export default function normalizeAnswerGroup(answerGroup: AnswerGroup, auditId: string) {
    const {answers, ...rest} = answerGroup;

    return merge({
        answerGroups: {
            [auditId]: undefinity({
                [answerGroup.questionGroupId]: undefinity(rest)
            }),
        },
    }, ...answers.map(answer => normalizeAnswer(answer, auditId)))
}