import React from "react";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import Measure from "./Measure";
import MeasureAddButton from "./MeasureAddButton";

type Props = {
    auditId: string,
    questionId: number
}

export default function Measures({auditId, questionId}: Props) {
    const {t} = useTranslation();
    const measureIds = useAuditEditSelector(state => state.answers[auditId]?.[questionId]?.measureIds || []);

    if (!measureIds.length) {
        return null;
    }

    return (
        <div className="mt-4">
            <h6>{t('Measures')}:</h6>
            <div className="d-flex flex-wrap">
                {measureIds.map(measureId => (
                    <Measure auditId={auditId} questionId={questionId} measureId={measureId}/>
                ))}
            </div>
            <MeasureAddButton auditId={auditId} questionId={questionId}/>
        </div>
    );
}