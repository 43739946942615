import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ResponsiblePerson from "../../api/types/ResponsiblePerson";

type Props = {
    value: Partial<ResponsiblePerson>
    onChange: (value: Partial<ResponsiblePerson>) => void
}

export default function ResponsiblePersonForm({value, onChange}: Props) {
    const {t} = useTranslation();

    return (
        <div className="pt-3 pb-3">
            <label className="form-label">
                {t('Person responsible for the building drinking water installation according to Art. 73 LGV')
                    .split('\n')
                    .map((line, i) => (
                        <span key={i} className="d-block">{line}</span>
                    ))}
            </label>

            <div className="row">
                <div className="col-md-6">
                    <Field label={t('Name/Company')} value={value.name} required
                           onChange={name => onChange({...value, name})}/>
                    <Field label={t('Street')} value={value.street}
                           onChange={street => onChange({...value, street})}/>
                    <Field label={t('ZIP/City')} value={value.zipTown}
                           onChange={zipTown => onChange({...value, zipTown})}/>
                    <Field label={t('responsible person')} value={value.responsible}
                           onChange={responsible => onChange({...value, responsible})}/>
                    <Field label={t('Function')} value={value.function}
                           onChange={val => onChange({...value, function: val})}/>
                    <Field label={t('Telephone number')} value={value.phone} required
                           onChange={phone => onChange({...value, phone})}/>
                    <Field label={t('E-Mail')} value={value.email} required type="email"
                           onChange={email => onChange({...value, email})}/>
                </div>
                <div className="col-md-6">
                    <Field label={t('Deputy person')} value={value.deputy}
                           onChange={deputy => onChange({...value, deputy})}/>
                    <Field label={t('Function')} value={value.deputyFunction}
                           onChange={deputyFunction => onChange({...value, deputyFunction})}/>
                    <Field label={t('Telephone number')} value={value.deputyPhone}
                           onChange={deputyPhone => onChange({...value, deputyPhone})}/>
                    <Field label={t('E-Mail')} value={value.deputyEmail} type="email"
                           onChange={deputyEmail => onChange({...value, deputyEmail})}/>
                </div>
            </div>
        </div>
    )
}

type FieldProps = {
    required?: boolean
    label: string
    type?: 'email'
    value?: string
    onChange: (value: string) => void
}

let fieldCount = 0;

function Field({label, type, value, required, onChange}: FieldProps) {
    const [id] = useState('responsible-person-field-' + (++fieldCount));

    return (
        <div className="mb-2 d-flex align-items-start">
            <label className="col col-5 col-form-label col-form-label-sm" htmlFor={id}>{label}</label>
            <div className="col-7">
                <input id={id} className="form-control form-control-sm" value={value}
                       type={type}
                       required={required}
                       onChange={e => onChange(e.target.value)}/>
            </div>
        </div>
    )
}