import React, {useEffect, useMemo} from "react";
import Pagination from "../../common/components/Paginaton";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useAuditListSelector from "../hooks/useAuditEditSelector";
import selectChecklistIdsPaginated from "../selectors/selectChecklistIdsPaginated";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import AuditListRows from "./AuditListRows";
import useQueryNumber from "../../router/hooks/useQueryNumber";

export const CHECKLIST_TABLE_COLS = 5;

export default function AuditList() {
    const {t} = useTranslation();
    const [page, setPage] = useQueryNumber('page', 1);
    const checklistIdsPaginated = useAuditListSelector(state => selectChecklistIdsPaginated(state, 15));
    const checklistIds = useMemo(() => checklistIdsPaginated[page - 1] ?? [], [page, checklistIdsPaginated]);
    const loaded = useAuditListSelector(state => state.auditIds !== undefined);
    const baseUrl = useBaseUrl();

    useEffect(() => {
        if (loaded && page !== 1 && !checklistIds.length) {
            setPage(1);
        }
    }, [loaded, checklistIds, page, setPage]);

    if (page !== 1 && !checklistIds.length) {
        return null;
    }

    return (
        <div className="d-flex flex-column">
            <div className="flex-grow-1">
                <table className="table table-sm">
                    <thead>
                    <tr className="bg-secondary bg-opacity-25">
                        <th>{t('Checklist')}</th>
                        <th>{t('Language')}</th>
                        <th>{t('Date')}</th>
                        <th>{t('State')}</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {checklistIds.length === 0 && loaded && (
                        <tr>
                            <td colSpan={CHECKLIST_TABLE_COLS}>
                                <p className="text-center m-3">{t('No entries')}</p>
                                <p className="text-center"><Link
                                    to={`${baseUrl}/new`}>{t('Start new checklist')}</Link></p>
                            </td>
                        </tr>
                    )}
                    {checklistIds.map(checklistId => <AuditListRows key={checklistId} checklistId={checklistId}/>)}
                    </tbody>
                </table>
            </div>
            <Pagination pages={checklistIdsPaginated.length} {...{page, setPage}} />
        </div>
    );
}