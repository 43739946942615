import {createSelector} from 'reselect'
import findStep from "../helpers/findStep";
import selectCurrentGroupStep from "./selectCurrentGroupStep";
import selectGroupSteps from "./selectGroupSteps";
import isStepEqual from "../helpers/isStepEqual";

const selectNextGroupStep = createSelector(
    selectCurrentGroupStep,
    selectGroupSteps,
    (currentGroupStep, groupSteps) => {
        if (!currentGroupStep) return undefined;
        return findStep(groupSteps, currentGroupStep, 1);
    },
    {memoizeOptions: {resultEqualityCheck: isStepEqual}}
);

export default selectNextGroupStep;