import {v4 as uuid} from "uuid";
import AnswerLine from "../../api/types/AnswerLine";

export default function createAnswerLine(): AnswerLine {
    return {
        identity: uuid(),
        label: '',
        value: null
    }
}
