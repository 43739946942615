import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";

const deleteAudit = createAsyncThunk(
    'deleteAudit',
    async ({projectId, auditId}: { projectId: string, auditId: string }) => {
        await request('DELETE', `/api/projects/${projectId}/audits/${auditId}`);
    }
);

export default deleteAudit;