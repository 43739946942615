import React from "react";
import useUsersSelector from "../hooks/useUsersSelector";
import removeUndefinedFromList from "../../common/helpers/removeUndefinedFromList";

type Props = {
    id?: string
    value?: string
    placeholder?: string
    className?: string
    onChange: (userId?: string) => void
}

export default function UserSelect({value, onChange, id, placeholder, className}: Props) {
    const state = useUsersSelector(state => state.state);
    const users = useUsersSelector(state => state.userIds.map(id => state.users[id]));

    return (
        <select
            id={id}
            className={"form-select" + (className ? ' ' + className : '')}
            value={value || ''}
            onChange={e => onChange(e.target.value || undefined)}>
            <option value="">{placeholder}</option>
            {state !== 'loaded' && value ? <option value={value}>{value}</option> : null}
            {removeUndefinedFromList(users).map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
        </select>
    );
}