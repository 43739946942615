import React from "react";
import {useTranslation} from "react-i18next";
import useQueryFlag from "../../router/hooks/useQueryFlag";

export default function ShowDoneFilter() {
    const [showDone, setShowDone] = useQueryFlag('show-done');
    const {t} = useTranslation();

    return (
        <div className="form-check me-3">
            <input onChange={e => setShowDone(e.target.checked)} type="checkbox"
                   className="form-check-input" id="showDone" checked={showDone}/>
            <label className="form-check-label text-nowrap"
                   htmlFor="showDone">{t('Show completed items')}</label>
        </div>
    );
}