import React from "react";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import Comment from "./Comment";
import CommentAddButton from "./CommentAddButton";

type Props = {
    auditId: string,
    questionId: number
}

export default function Comments({auditId, questionId}: Props) {
    const {t} = useTranslation();
    const commentIds = useAuditEditSelector(state => state.answers[auditId]?.[questionId]?.commentIds || []);

    if (!commentIds.length) {
        return null;
    }

    return (
        <div className="mt-4">
            <h6>{t('Comments')}:</h6>
            <div className="d-flex flex-wrap w-100">
                {commentIds.map(commentId => (
                    <Comment auditId={auditId} questionId={questionId} commentId={commentId}/>
                ))}
            </div>
            <CommentAddButton auditId={auditId} questionId={questionId}/>
        </div>
    );
}