import Layout from "./Layout";
import getProjectPortalUrl from "../helpers/getProjectPortalUrl";
import {Trans} from "react-i18next";
import useLocale from "../hooks/useLocale";

export function AccessDeniedPage() {
    const locale = useLocale();

    return (
        <Layout>
            <Trans
                i18nKey="You do not have access to the checklists. Please start the app via the <1>web app project portal</1>.">
                You do not have access to the checklists. Please start the app via the <a
                href={getProjectPortalUrl(locale)}>web app project portal</a>
            </Trans>
        </Layout>
    )
}