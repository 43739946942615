import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faFilePdf, faList, faTrash} from '@fortawesome/free-solid-svg-icons'
import Audit from "../../api/types/Audit";
import getApiUrl from "../../api/helpers/getApiUrl";
import {useTranslation} from "react-i18next";
import useDisplayLanguage from "../../common/hooks/useDisplayLanguage";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import ChecklistStartButton from "../../audit-new/compoenents/ChecklistStartButton";
import useDispatch from "../../common/hooks/useDispatch";
import deleteAudit from "../actions/deleteAudit";
import useProjectId from "../../app/hooks/useProjectId";

type Props = {
    checklistId: number
    audit: Audit
}

export default function AuditListRow({audit, checklistId}: Props) {
    const displayLanguage = useDisplayLanguage();
    const {t} = useTranslation();
    const baseUrl = useBaseUrl();
    const dispatch = useDispatch();
    const projectId = useProjectId();

    const remove = async () => {
        await dispatch(deleteAudit({projectId, auditId: audit.id}));
    };

    return (
        <tr>
            <td style={{width: '100%'}}>{audit.reason}</td>
            <td className="text-nowrap">{displayLanguage(audit.language)}</td>
            <td className="text-nowrap">{moment(audit.updatedAt).format('D. MMM  YYYY')}</td>
            <td className="text-nowrap">
                {audit.completedAt ? t('Completed') : t('In Progress')}
            </td>
            <td className="text-right">
                <div className="d-flex align-items-end justify-content-end text-nowrap">
                    <a className="pe-2"
                       href={getApiUrl(`/projects/${projectId}/audits/${audit.id}.pdf`)}
                       target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon title={t('Checklist report')} icon={faFilePdf}/>
                    </a>
                    {!audit.completedAt ?
                        <Link to={`${baseUrl}/audits/${audit.id}`} className="pe-2">
                            <FontAwesomeIcon title={t('Edit checklist')} icon={faEdit}/>
                        </Link> :
                        null
                    }
                    {audit.completedAt ?
                        <Link to={`${baseUrl}/measures?audit=${audit.id}`} className="pe-2">
                            <FontAwesomeIcon title={t('Measures')} icon={faList}/>
                        </Link> :
                        null
                    }
                    <ChecklistStartButton checklistId={checklistId} copyFromAudit={audit}/>
                    {!audit.completedAt ?
                        <button className="btn btn-link p-0" onClick={remove}>
                            <FontAwesomeIcon title={t('Delete checklist')} icon={faTrash}/>
                        </button> :
                        null
                    }
                </div>
            </td>
        </tr>
    );
}