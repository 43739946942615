import moment from "moment";
import MeasureDoneToggle from "./MeasureDoneToggle";
import React from "react";
import useMeasureListSelector from "../hooks/useMeasureListSelector";
import useUsersSelector from "../../users/hooks/useUsersSelector";
import {useTranslation} from "react-i18next";
import useQueryFlag from "../../router/hooks/useQueryFlag";

type Props = {
    measureId: string
}
export default function MeasureListRow({measureId}: Props) {
    const {t} = useTranslation();
    const measure = useMeasureListSelector(state => state.measures[measureId]);
    const user = useUsersSelector(state => state.users[measure?.responsible ?? -1]);
    const isResponsibleMe = useUsersSelector(state => state.me?.id === measure?.responsible);
    const [showDone] = useQueryFlag('show-done');

    if (!measure) {
        return null;
    }

    return (
        <tr>
            <td>{measure.measure}</td>
            <td>
                {measure.audit?.reason ? (
                    <div>
                        <div className="badge text-white bg-secondary">{measure.audit?.checklistVersion?.name}</div>
                        <div className="small">{measure.audit?.reason}</div>
                    </div>
                ) : measure.audit?.checklistVersion?.name}
            </td>
            <td>
                {measure.question?.positionLabel && (
                    <span className="badge bg-secondary me-2">{measure.question?.positionLabel}</span>
                )}
                {measure.question?.question}
            </td>
            <td className="text-nowrap">{measure.dueDate ? moment(measure.dueDate).format('D. MMM  YYYY') : '-'}</td>
            <td className="text-nowrap">{user?.name || measure.responsible}</td>
            {showDone && (
                <td className="text-nowrap">{measure.controlDate ? (
                    <span>{t('Done')}<span
                        className="ps-2">({moment(measure.controlDate).format('D. MMM  YYYY')})</span></span>
                ) : t('Not done')}</td>
            )}
            <td>
                {isResponsibleMe && <MeasureDoneToggle measureId={measureId}/>}
            </td>
        </tr>
    )
}