import React from "react";
import {pull} from "lodash";
import {useTranslation} from "react-i18next";
import FormCheck from "../../common/components/FormCheck";
import Card from "../../common/components/Card";
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import removeUndefinedFromList from "../../common/helpers/removeUndefinedFromList";
import selectTopicIdsFiltered from "../selectors/selectTopicIdsFiltered";
import useQueryNumbers from "../../router/hooks/useQueryNumbers";
import useQueryValues from "../../router/hooks/useQueryValues";

export default function TopicsFilter() {
    const {t} = useTranslation()
    const [topicIds, setTopicIds] = useQueryNumbers('topic');
    const [languages] = useQueryValues('language');
    const [areaIds] = useQueryNumbers('area');
    const filteredTopics = useAuditNewSelector(
        state => selectTopicIdsFiltered(state, {languages, areaIds}).flatMap(
            id => state.topics[id] ? state.topics[id] : [])
    );

    return (
        <Card title={t('Topics')}>
            {removeUndefinedFromList(filteredTopics).map(topic => (
                <FormCheck
                    key={topic.id}
                    onChange={checked => setTopicIds(checked ? [...topicIds, topic.id] : pull(topicIds, topic.id))}
                    checked={topicIds.indexOf(topic.id) !== -1}
                >
                    {topic.name}
                </FormCheck>
            ))}
        </Card>
    );
}