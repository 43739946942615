import React from "react";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import QuestionPriority from "./QuestionPriority";
import selectChecklistVersion from "../selectors/selectChecklistVersion";
import Photos from "./Photos";
import PhotoAddButton from "./PhotoAddButton";
import Measures from "./Measures";
import MeasureAddButton from "./MeasureAddButton";
import Comments from "./Comments";
import CommentAddButton from "./CommentAddButton";
import AnswerValueForm from "./AnswerValueForm";

type Props = {
    auditId: string,
    questionId: number
}

function Answer({auditId, questionId}: Props) {
    const checklistVersion = useAuditEditSelector(selectChecklistVersion);
    const question = useAuditEditSelector(state => state.questions[questionId]);
    const hasComments = useAuditEditSelector(state =>
        (state.answers[auditId]?.[questionId]?.commentIds.length || 0) > 0);
    const hasPhotos = useAuditEditSelector(state =>
        (state.answers[auditId]?.[questionId]?.photoIds.length || 0) > 0);
    const hasMeasures = useAuditEditSelector(state =>
        (state.answers[auditId]?.[questionId]?.measureIds.length || 0) > 0);

    if (!question || !checklistVersion) {
        return null;
    }

    return (
        <div className="mb-3">
            <label htmlFor={"question" + question.id} className="d-block">
                <h5 className="d-flex">
                    {question.positionLabel &&
                    <span className="badge bg-secondary me-3 align-self-start">{question.positionLabel}</span>}
                    {question.question}
                </h5>
            </label>
            <div className="mb-4" dangerouslySetInnerHTML={{__html: question.description}}/>
            <AnswerValueForm auditId={auditId} questionId={questionId}/>
            <QuestionPriority questionId={questionId}/>
            <ul className="list-unstyled mt-4">
                {checklistVersion.allowComments && !hasComments && (
                    <li><CommentAddButton auditId={auditId} questionId={questionId}/></li>
                )}
                {checklistVersion.allowMeasures && !hasMeasures && (
                    <li><MeasureAddButton auditId={auditId} questionId={questionId}/></li>
                )}
                {!hasPhotos && (
                    <li><PhotoAddButton auditId={auditId} questionId={questionId}/></li>
                )}
            </ul>
            {checklistVersion.allowComments && <Comments auditId={auditId} questionId={questionId}/>}
            {checklistVersion.allowMeasures && <Measures auditId={auditId} questionId={questionId}/>}
            <Photos auditId={auditId} questionId={questionId}/>
        </div>
    );
}

export default React.memo(Answer);