import normalizeAnswer from "../normalizer/normalizeAnswer";
import denormalizeMeasure from "./denormalizeMeasure";
import denormalizeComment from "./denormalizeComment";
import denormalizePhoto from "./denormalizePhoto";
import Answer from "../../api/types/Answer";

type Normalized = ReturnType<typeof normalizeAnswer>;

export default function denormalizeAnswer(normalized: Normalized, auditId: string, questionId: number): Answer | undefined {
    const answer = normalized.answers[auditId]?.[questionId];

    if (!answer) {
        console.error('Answer could not be denormalized. Answer not found:', {auditId, normalized});
        return undefined;
    }

    const {photoIds, measureIds, commentIds, ...rest} = answer;

    return {
        ...rest,
        measures: measureIds.flatMap(measureId => {
            const measure = denormalizeMeasure(normalized, measureId);
            return measure ? [measure] : [];
        }),
        comments: commentIds.flatMap(commentId => {
            const comment = denormalizeComment(normalized, commentId);
            return comment ? [comment] : [];
        }),
        photos: photoIds.flatMap(photoId => {
            const photo = denormalizePhoto(normalized, photoId);
            return photo ? [photo] : [];
        }),
    }
}