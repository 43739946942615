import {createCachedSelector} from 're-reselect';
import AuditEditState from "../types/AuditEditState";
import selectQuestionSteps from "./selectQuestionSteps";
import stepsEqual from "../helpers/stepsEqual";
import {createSelectorCreator, defaultMemoize} from 'reselect'

const selectAnsweredQuestionSteps = createCachedSelector(
    (steps: AuditEditState, questionGroupId: number | 'All') => selectQuestionSteps(steps, questionGroupId),
    (state: AuditEditState) => state.answers[state.auditId ?? -1] || {},
    (steps, answers) => {
        return steps.filter(step => answers[step.questionId]?.value !== null)
    }
)({
    selectorCreator: createSelectorCreator(defaultMemoize, {resultEqualityCheck: stepsEqual}),
    keySelector: (state, questionGroupId) => questionGroupId
});

export default selectAnsweredQuestionSteps;