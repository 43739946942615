import React from "react";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import Photo from "./Photo";
import PhotoAddButton from "./PhotoAddButton";

type Props = {
    auditId: string,
    questionId: number
}

export default function Photos({auditId, questionId}: Props) {
    const {t} = useTranslation();
    const photoIds = useAuditEditSelector(state => state.answers[auditId]?.[questionId]?.photoIds || []);

    if (!photoIds.length) {
        return null;
    }

    return (
        <div className="mt-4">
            <h6>{t('Photos')}:</h6>
            <div className="d-flex flex-wrap">
                {photoIds.map(photoId => (
                    <Photo auditId={auditId} questionId={questionId} photoId={photoId}/>
                ))}
            </div>
            <PhotoAddButton auditId={auditId} questionId={questionId}/>
        </div>
    );
};