import {createCachedSelector} from 're-reselect';
import AuditEditState from "../types/AuditEditState";

const selectAnswer = createCachedSelector(
    (state: AuditEditState) => state.answers[state.auditId ?? -1] || {},
    (state: AuditEditState, questionId: number) => questionId,
    (answers, questionId) => {
        return answers[questionId];
    }
)({
    keySelector: (state, answerId) => answerId
});

export default selectAnswer;