import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import displayLanguage from "../helpers/displayLanguage";

export default function useDisplayLanguage() {
    const {i18n} = useTranslation();

    return useCallback((language: string) => {
        return displayLanguage(language, i18n.language);
    }, [i18n.language])
}