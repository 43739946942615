import AuditEditState from "../types/AuditEditState";

export default function createAuditEditState(): AuditEditState {
    return {
        updateFailed: false,
        status: {},
        questionGroups: {},
        answerGroups: {},
        answers: {},
        questions: {},
        audits: {},
        checklistVersions: {},
        photos: {},
        measures: {},
        comments: {},
    }
}