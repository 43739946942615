import Layout from "./Layout";
import React from "react";
import {useTranslation} from "react-i18next";

export default function ErrorPage() {
    const {t} = useTranslation();

    return (
        <Layout>
            {t('An error occurred.')}
        </Layout>
    );
}