import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight, faFilePdf, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useStepNav from "../hooks/useStepNav";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import {useHistory} from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";
import getApiUrl from "../../api/helpers/getApiUrl";
import useProjectId from "../../app/hooks/useProjectId";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import AuditEditModal from "./AuditEditModal";

export default function NavButtons() {
    const {t} = useTranslation();
    const [prev, next] = useStepNav();
    const projectId = useProjectId();
    const baseUrl = useBaseUrl();
    const history = useHistory();
    const auditId = useAuditEditSelector(state => state.auditId);

    const close = () => {
        history.push(baseUrl);
    }

    useEffect(() => {
        const keyDown = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Tab':
                case 'ArrowRight':
                    if (next) next();
                    break;
                case 'ArrowLeft':
                    if (prev) prev();
                    break;
            }
        }

        document.addEventListener('keydown', keyDown);

        return () => {
            document.removeEventListener('keydown', keyDown)
        }
    }, [prev, next]);

    return (
        <div className="text-center mb-3 mt-3" style={{userSelect: "none"}}>
            <OverlayTrigger overlay={<Popover><Popover.Body>{t('Back')}</Popover.Body></Popover>}>
                <button onClick={prev}
                        className={"btn btn-secondary float-start" + (!prev ? ' disabled' : '')}>
                    <FontAwesomeIcon title={t('Back')} icon={faCaretLeft}/>
                </button>
            </OverlayTrigger>


            {auditId && <AuditEditModal auditId={auditId}/>}

            <OverlayTrigger overlay={<Popover><Popover.Body>{t('Close')}</Popover.Body></Popover>}>
                <button onClick={close} className="btn btn-secondary">
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Popover><Popover.Body>{t('Checklist report')}</Popover.Body></Popover>}>
                <a className="btn btn-secondary ms-2"
                   href={getApiUrl(`/projects/${projectId}/audits/${auditId}.pdf`)}
                   target="_blank"
                   rel="noreferrer">
                    <FontAwesomeIcon title={t('Checklist report')} icon={faFilePdf}/>
                </a>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Popover><Popover.Body>{t('Next')}</Popover.Body></Popover>}>
                <button onClick={next}
                        className={"btn btn-secondary float-end" + (!next ? ' disabled' : '')}>
                    <FontAwesomeIcon icon={faCaretRight}/>
                </button>
            </OverlayTrigger>
        </div>
    );
}