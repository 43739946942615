import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import AuditDetail from "../../api/types/AuditDetail";
import ChecklistVersionDetail from "../../api/types/ChecklistVersionDetail";

const fetchAudit = createAsyncThunk(
    'fetchAudit',
    async ({projectId, auditId}: { projectId: string, auditId: string }) => {
        const audit = await request<AuditDetail>('GET', `/api/projects/${projectId}/audits/${auditId}`);
        const checklistVersion = await request<ChecklistVersionDetail>('GET', `/api/checklist_versions/${audit.checklistVersionId}?locale=${audit.language}`);
        const auditCopyFrom = audit.copyFromId ? await request<AuditDetail>('GET', `/api/projects/${projectId}/audits/${audit.copyFromId}?locale=${audit.language}`) : undefined;

        return {audit, checklistVersion, auditCopyFrom};
    }
);

export default fetchAudit;