import React from "react";
import {Link} from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";
import QuestionStep from "../types/QuestionStep";
import getStepPath from "../helpers/getStepPath";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import useStepPath from "../hooks/useStepPath";
import isStepEqual from "../helpers/isStepEqual";

type Props = {
    step: QuestionStep
}

export default function IndicatorItem({step}: Props) {
    const title = useAuditEditSelector(state => state.questions[step.questionId]?.question);
    const isActive = useAuditEditSelector(state => isStepEqual(state.step, step));
    const path = useStepPath(step);

    return (
        <li className={"indicator-item" + (isActive ? ' active' : '')}>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Popover id={"IndicatorItem-popover-" + getStepPath(step).replace('/', '_')}>
                        <Popover.Body>
                            {title}
                        </Popover.Body>
                    </Popover>
                }
            >
                <Link to={path}/>
            </OverlayTrigger>
        </li>
    );
}