import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'typeface-roboto'
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import reportWebVitals from "./reportWebVitals";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/fr';
import './i18n';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AuditListPage from "./packages/audit-list/components/AuditListPage";
import {AccessDeniedPage} from "./packages/app/components/AccessDeniedPage";
import NotFoundPage from "./packages/app/components/NotFoundPage";
import {Provider} from "react-redux";
import {store} from "./store";
import AuditEditPage from "./packages/audit-edit/components/AuditEditPage";
import AuditNewPage from "./packages/audit-new/compoenents/AuditNewPage";
import MeasureListPage from "./packages/measure-list/components/MeasureListPage";
import fetchMe from "./packages/users/actions/fetchMe";
import fetchUsers from "./packages/users/actions/fetchUsers";
import "./matomo";

store.dispatch(fetchMe());
store.dispatch(fetchUsers());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router basename={process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname : undefined}>
                <Switch>
                    <Route exact path={["/", "/:locale"]}><AccessDeniedPage/></Route>
                    <Route exact path="/:locale/:projectId"><AuditListPage/></Route>
                    <Route exact path="/:locale/:projectId/measures"><MeasureListPage/></Route>
                    <Route exact path="/:locale/:projectId/new"><AuditNewPage/></Route>
                    <Route exact path={[
                        "/:locale/:projectId/audits/:auditId/:stepName/:stepRefId",
                        "/:locale/:projectId/audits/:auditId/:stepName",
                        "/:locale/:projectId/audits/:auditId",
                    ]}>
                        <AuditEditPage/>
                    </Route>
                    <Route><NotFoundPage/></Route>
                </Switch>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();