import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {useDispatch} from "react-redux";
import {auditEditActions} from "../auditEditSlice";

type Props = {
    auditId: string
    questionId: number
    commentId: string
}

export default function Comment({auditId, questionId, commentId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const comment = useAuditEditSelector(state => state.comments[commentId])

    if (!comment) {
        return null;
    }

    const onRemove = () => {
        dispatch(auditEditActions.removeComment({auditId, questionId, commentId}));
    }

    const onChange = (values: Parameters<typeof auditEditActions.updateComment>[0]["values"]) => {
        dispatch(auditEditActions.updateComment({commentId, values}));
    }

    return (
        <div className="d-flex mb-3 w-100">
                 <textarea
                     onChange={e => onChange({comment: e.target.value})}
                     className="form-control flex-grow-1 me-3" value={comment.comment}
                 />
            <button className="btn btn-link p-0" onClick={onRemove}>
                <FontAwesomeIcon title={t('Delete comment')} icon={faTrash}/>
            </button>
        </div>
    );
}