import AuditDetail from "../../api/types/AuditDetail";
import normalizeAuditDetail from "../normalizer/normalizeAuditDetail";
import normalizeChecklistVersionDetail from "../normalizer/normalizeChecklistVersionDetail";
import denormalizeAnswerGroup from "./denormalizeAnswerGroup";

type Normalized = ReturnType<typeof normalizeAuditDetail> & ReturnType<typeof normalizeChecklistVersionDetail>;

export default function denormalizeAuditDetail(normalized: Normalized, auditId: string): AuditDetail | undefined {
    const audit = normalized.audits[auditId];
    const checklistVersion = normalized.checklistVersions[audit?.checklistVersionId ?? -1];

    if (!audit || !checklistVersion) {
        console.error('Audit could not be denormalized. Audit or checklist version not found:', {auditId, normalized})
        return undefined;
    }

    return {
        ...audit,
        answerGroups: checklistVersion.questionGroupIds.flatMap(questionGroupId => {
            const answerGroup = denormalizeAnswerGroup(normalized, auditId, questionGroupId);
            return answerGroup ? [answerGroup] : [];
        }),
    }
}