import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEraser, faSpinner} from "@fortawesome/free-solid-svg-icons";
import SignatureCanvas from "react-signature-canvas";
import FormCheck from "../../common/components/FormCheck";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {auditEditActions} from "../auditEditSlice";
import Repetition from "./Repetition";
import finishAudit from "../actions/finishAudit";
import useDispatch from "../../common/hooks/useDispatch";
import updateAudit from "../actions/updateAudit";
import denormalizeAuditDetail from "../denormalizer/denormalizeAuditDetail";
import useProjectId from "../../app/hooks/useProjectId";

type Props = {
    auditId: string
}

export default function Completion({auditId}: Props) {
    const signatureImage = useAuditEditSelector(state => state.audits[auditId]?.signatureImage);
    const signatureText = useAuditEditSelector(state => state.audits[auditId]?.signatureText);
    const checklistVersion = useAuditEditSelector(state => state.checklistVersions[state.audits[auditId]?.checklistVersionId ?? -1]);
    const completedAt = useAuditEditSelector(state => state.audits[auditId ?? -1]?.completedAt);

    const signatureImageRef = useRef(signatureImage)
    const [completed, setCompleted] = useState(!!completedAt);
    const dispatch = useDispatch();
    const signature = useRef<SignatureCanvas>(null);
    const history = useHistory();
    const [closing, setClosing] = useState(false);
    const baseUrl = useBaseUrl();
    const {t} = useTranslation();
    const projectId = useProjectId();

    useEffect(() => {
        const current = signature.current;
        const signatureImage = signatureImageRef.current;
        if (!current || !signatureImage) {
            return;
        }
        current.clear();
        current.fromDataURL(signatureImage);
    }, [signatureImageRef, signature]);

    const onChange = (values: Parameters<typeof auditEditActions.updateAudit>[0]['values']) => {
        dispatch(auditEditActions.updateAudit({auditId, values}));
    }

    const close = async () => {
        setClosing(true);
        dispatch(async (dispatch, getState) => {
            const audit = denormalizeAuditDetail(getState().auditEdit, auditId);

            const isUpdatedFailed = () => {
                return getState().auditEdit.updateFailed;
            };

            if (audit) {
                await dispatch(updateAudit({projectId, audit}));

                if (!isUpdatedFailed()) {
                    await dispatch(finishAudit({projectId, auditId}));
                }

                if (!isUpdatedFailed()) {
                    history.push(baseUrl);
                }
            }

            setClosing(false);
        });
    }

    const clearCanvas = () => {
        const current = signature.current;
        if (!current) {
            return;
        }

        current.clear();
        onChange({signatureImage: null});
    }

    const updateCanvas = () => {
        const current = signature.current;
        if (!current) {
            return;
        }

        onChange({signatureImage: current.getCanvas().toDataURL()});
    }

    return (
        <>
            <h2 className="mb-4">{t('Completion')}</h2>
            <Repetition auditId={auditId}/>

            {checklistVersion?.allowSignature ?
                <div style={{width: 400}} className="mb-4 mt-2">
                    <div>
                        <button className="float-end btn btn-link p-0" onClick={clearCanvas}><FontAwesomeIcon
                            title="Signatur löschen" icon={faEraser}/></button>
                        <SignatureCanvas
                            ref={signature}
                            onEnd={updateCanvas}
                            penColor="black"
                            canvasProps={{width: 400, height: 150, className: 'sigCanvas',}}
                        />
                    </div>
                    <input onChange={e => onChange({
                        signatureText: e.target.value
                    })} type="text" className="form-control" value={signatureText || ''}/>
                </div> : null
            }

            <FormCheck checked={completed}
                       onChange={setCompleted}>{t('I hereby confirm the correctness and completeness of the checklist.')}</FormCheck>

            <button onClick={close}
                    disabled={closing || !completed || (checklistVersion?.allowSignature && (!signatureImage || !signatureText))}
                    className="btn btn-success mt-4">
                {t('Complete')}
                {closing && <span className="ps-2"><FontAwesomeIcon spin={true} icon={faSpinner}/></span>}
            </button>
        </>
    );
}