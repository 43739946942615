// @ts-ignore
let _paq = window._paq = window._paq ?? [];

_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);

(function () {
    const u = process.env.REACT_APP_MATOMO_URL;

    if(!u) {
        return;
    }

    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '5']);
    const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';

    if(s.parentNode) {
        s.parentNode.insertBefore(g, s);
    }

    let currentUrl = window.location.href;
    window.addEventListener('hashchange', function() {
        _paq.push(['setReferrerUrl', currentUrl]);
        currentUrl = window.location.href;
        _paq.push(['setCustomUrl', currentUrl]);

        // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
        _paq.push(['deleteCustomVariables', 'page']);
        _paq.push(['trackPageView']);

        // make Matomo aware of newly added content
        const root = document.getElementById('root');
        _paq.push(['MediaAnalytics::scanForMedia', root]);
        _paq.push(['FormAnalytics::scanForForms', root]);
        _paq.push(['trackContentImpressionsWithinNode', root]);
        _paq.push(['enableLinkTracking']);
    });
})();