import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";

const finishAudit = createAsyncThunk(
    'finishAudit',
    async ({projectId, auditId}: { projectId: string, auditId: string }) => {
        await request('PATCH', `/api/projects/${projectId}/audits/${auditId}/finish`);
    }
);

export default finishAudit;