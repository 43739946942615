import React, {useEffect, useMemo} from "react";
import Pagination from "../../common/components/Paginaton";
import {useTranslation} from "react-i18next";
import useAuditListSelector from "../../audit-list/hooks/useAuditEditSelector";
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import selectChecklistIdsFilteredPaginated from "../selectors/selectChecklistIdsFilteredPaginated";
import ChecklistListRow from "./ChecklistListRow";
import useQueryValues from "../../router/hooks/useQueryValues";
import useQueryNumbers from "../../router/hooks/useQueryNumbers";
import useQueryNumber from "../../router/hooks/useQueryNumber";

export const CHECKLIST_TABLE_COLS = 4;

export default function ChecklistList() {
    const {t} = useTranslation();
    const [languages] = useQueryValues('language');
    const [topicIds] = useQueryNumbers('topic');
    const [areaIds] = useQueryNumbers('area');
    const [page, setPage] = useQueryNumber('page', 1);
    const checklistIdsPaginated = useAuditNewSelector(state => selectChecklistIdsFilteredPaginated(state, {
        languages, topicIds, areaIds
    }, 15));
    const checklistIds = useMemo(() => checklistIdsPaginated[page - 1] ?? [], [page, checklistIdsPaginated]);
    const loaded = useAuditListSelector(state => state.status === "loaded");

    useEffect(() => {
        if (loaded && page !== 1 && !checklistIds.length) {
            setPage(1);
        }
    }, [loaded, checklistIds, page, setPage]);


    return (
        <div className="h-100">
            <table className="table table-sm table-striped">
                <thead>
                <tr className="bg-secondary bg-opacity-25">
                    <th>{t('Checklist')}</th>
                    <th>{t('Topics')}</th>
                    <th>{t('Language')}</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {checklistIds.length === 0 && loaded && (
                    <tr>
                        <td colSpan={CHECKLIST_TABLE_COLS}>{t('No entries')}</td>
                    </tr>
                )}
                {checklistIds.map(checklistId => (
                    <ChecklistListRow key={checklistId} checklistId={checklistId}/>
                ))}
                </tbody>
            </table>
            <Pagination pages={checklistIdsPaginated.length} {...{page, setPage}} />
        </div>
    );
}