import useAuditEditSelector from "./useAuditEditSelector";
import selectPrevStep from "../selectors/selectPrevStep";
import selectNextStep from "../selectors/selectNextStep";

import {useHistory} from "react-router-dom";
import {useCallback} from "react";
import selectCurrentStepValid from "../selectors/selectCurrentStepValid";
import useStepPath from "./useStepPath";

export default function useStepNav() {
    const history = useHistory()
    const prevPath = useStepPath(useAuditEditSelector(selectPrevStep));
    const nextPath = useStepPath(useAuditEditSelector(selectNextStep));
    const currentStepValid = useAuditEditSelector(selectCurrentStepValid);
    const goPrev = useCallback(() => {
        if (prevPath) {
            history.push(prevPath);
        }
    }, [history, prevPath]);

    const goNext = useCallback(() => {
        if (nextPath) {
            history.push(nextPath);
        }
    }, [history, nextPath]);

    return [prevPath ? goPrev : undefined, nextPath && currentStepValid ? goNext : undefined];
}