import createCachedSelector from "re-reselect";
import AuditNewState from "../types/AuditNewState";
import Filter from "../types/Filter";

const selectChecklistIdsFiltered = createCachedSelector(
    (state: AuditNewState) => state.checklists,
    (state: AuditNewState) => state.checklistIds,
    (state: AuditNewState, filter: Filter) => filter,
    (checklists, checklistIds, filter) => {
        return checklistIds.filter(checklistId => {
            const {areaIds, topicIds, languages} = filter;
            const checklist = checklists[checklistId];
            if (!checklist) return false;

            if (areaIds?.length && !checklist.areaIds.some(id => areaIds.includes(id))) {
                return false;
            }

            if (topicIds?.length && !checklist.topicIds.some(id => topicIds.includes(id))) {
                return false;
            }

            return !(languages?.length && !checklist.languages.some(language => languages.includes(language)));
        })
    }
)({
    keySelector: (state, filter) => JSON.stringify(filter)
});

export default selectChecklistIdsFiltered;