import React from "react";
import {useTranslation} from "react-i18next";
import AuditFilterOption from "./AuditFilterOption";
import useQueryValue from "../../router/hooks/useQueryValue";
import selectAuditData from "../selectors/selectAuditData";
import useMeasureListSelector from "../hooks/useMeasureListSelector";

export default function AuditFilter() {
    const [auditId, setAuditId] = useQueryValue('audit');
    const {auditIds} = useMeasureListSelector(selectAuditData);
    const {t} = useTranslation();

    return (
        <div style={{width: 200}}>
            <select className="form-select form-select-sm" value={auditId}
                    onChange={e => setAuditId(e.target.value)}>
                <option value="">{t('All checklists')}</option>
                {auditIds.map(auditId => (
                    <AuditFilterOption key={auditId} auditId={auditId}/>
                ))}
            </select>
        </div>
    );
}