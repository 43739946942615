import {createSlice} from "@reduxjs/toolkit";
import fetchMeasures from "./actions/fetchMeasures";
import createMeasureListState from "./factories/createMeasureListState";
import toggleDone from "./actions/toggleDone";
import moment from "moment";

const measureListSlice = createSlice({
    name: 'measureList',
    initialState: createMeasureListState(),
    reducers: {
        resetStatus: state => {
            delete state.status;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(toggleDone.fulfilled, (state, {meta}) => {
            const {measureId, done} = meta.arg;
            const measure = state.measures[measureId];

            if (measure) {
                measure.controlDate = done ? moment().format() : null;
            }
        });

        builder.addCase(fetchMeasures.pending, state => {
            state.status = 'loading';
        })
        builder
            .addCase(fetchMeasures.rejected, (state, {error}) => {
                switch (error.code) {
                    case '401':
                    case '403':
                        state.status = 'forbidden';
                        break;
                    default:
                        state.status = 'error';
                }
            })
        builder
            .addCase(fetchMeasures.fulfilled, (state, {payload}) => {
                const {measures} = payload;
                state.status = 'loaded';

                state.status = 'loaded';
                state.measureIds = measures.map(measure => {
                    state.measures[measure.id] = measure;
                    return measure.id;
                })
            });
    },
});

export const measureListReducer = measureListSlice.reducer;
export const measureListActions = measureListSlice.actions;

