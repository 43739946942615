import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import User from "../../api/types/User";

const fetchMe = createAsyncThunk(
    'fetchMe',
    async () => {
        return await request<User>('GET', '/api/users/me');
    }
);

export default fetchMe;
