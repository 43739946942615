import {createSelector} from "reselect";
import AuditListState from "../types/AuditListState";

const selectGroupedByChecklistData = createSelector(
    (state: AuditListState) => state.auditIds,
    (state: AuditListState) => state.audits,
    (auditIds, audits) => {
        let checklistIds: number[] = [];
        let checklistNames: { [key: number]: string | undefined } = {};
        let groupedByChecklist: { [checklistId: number]: string[] | undefined } = {};

        auditIds?.forEach(auditId => {
            const audit = audits[auditId];
            const checklist = audit?.checklist;
            if (!audit || !checklist) return;

            const checklistId = checklist.id;

            checklistNames[checklistId] = checklist.name;

            if (!groupedByChecklist[checklistId]) {
                groupedByChecklist[checklistId] = [];
                checklistIds.push((checklistId));
            }

            (groupedByChecklist[checklistId] ?? []).push(auditId);
        })

        return {
            checklistNames,
            checklistIds,
            groupedByChecklist,
        }
    }
);

export default selectGroupedByChecklistData;