import {createSelector} from 'reselect'
import AuditEditState from "../types/AuditEditState";
import findStep from "../helpers/findStep";
import selectSteps from "./selectSteps";
import isStepEqual from "../helpers/isStepEqual";
import Step from "../types/Step";

const selectPrevStep = createSelector(
    selectSteps,
    (state: AuditEditState) => state.step,
    (steps, currentStep) => {
        if (!currentStep) {
            return undefined;
        }

        let prevStep: Step | undefined = currentStep;

        while (true) {
            prevStep = findStep(steps, prevStep, -1);

            if (prevStep?.name !== 'question-group' || !prevStep.parent) {
                break;
            }
        }

        return prevStep;
    },
    {memoizeOptions: {resultEqualityCheck: isStepEqual}}
);

export default selectPrevStep;