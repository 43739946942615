import AuditListState from "../types/AuditListState";
import selectGroupedByChecklistData from "./selectGroupedByChecklistData";
import createCachedSelector from "re-reselect";

const selectChecklistNameById = createCachedSelector(
    selectGroupedByChecklistData,
    (state: AuditListState, checklistId: number) => checklistId,
    ({checklistNames}, checklistId) => {
        return checklistNames[checklistId];
    }
)({
    keySelector: (state, checklistId) => checklistId
});

export default selectChecklistNameById;