import {useCallback, useMemo} from "react";
import useQueryValues from "./useQueryValues";

export default function useQueryNumbers(name: string, separator: string = '-'): [number[], (values: number[]) => void] {
    const [values, setValues] = useQueryValues(name, separator);

    const numbers = useMemo(() => values.map(v => parseInt(v, 10)), [values]);
    const setNumbers = useCallback((numbers: number[]) => {
        setValues(numbers.map(n => n.toString()));
    }, [setValues])

    return [numbers, setNumbers];
}