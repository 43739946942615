import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {useDispatch} from "react-redux";
import {auditEditActions} from "../auditEditSlice";

type Props = {
    auditId: string
    questionId: number
    photoId: string
}

export default function Photo({auditId, questionId, photoId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const photoUrl = useAuditEditSelector(state => state.photos[photoId]?.url)

    if (!photoUrl) {
        return null;
    }

    const onRemove = () => {
        dispatch(auditEditActions.removePhoto({auditId, questionId, photoId}));
    }

    const url = photoUrl.startsWith('blob:') ? photoUrl : new URL((process.env.REACT_APP_API_URL || '') + '/' + photoUrl, window.location.href).toString();

    return (
        <div className="mb-3 h-100 position-relative" style={{maxWidth: 350}}>
            <img className="img-thumbnail h-100" alt={photoId} src={url}/>
            <button className="btn btn-link p-0 position-absolute" style={{top: -20, right: 0}} onClick={onRemove}>
                <FontAwesomeIcon title={t('Delete photo')} icon={faTimes}/>
            </button>
        </div>
    );
}