import React, {useEffect} from "react";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import useUpdateStateByParams from "../hooks/useUpdateStateByParams";
import {useDispatch} from "react-redux";
import Layout from "../../app/components/Layout";
import selectChecklistVersion from "../selectors/selectChecklistVersion";
import StepNav from "./StepNav";
import Indicator from "./Indicator";
import NavButtons from "./NavButtons";
import Answer from "./Answer";
import AnswerGroup from "./AnswerGroup";
import Completion from "./Completion";
import RedirectToLastQuestion from "./RedirectToLastQuestion";
import {AccessDeniedPage} from "../../app/components/AccessDeniedPage";
import NotFoundPage from "../../app/components/NotFoundPage";
import ErrorPage from "../../app/components/ErrorPage";
import syncAudit from "../helpers/syncAudit";
import fetchAudit from "../actions/fetchAudit";
import useProjectId from "../../app/hooks/useProjectId";
import {Trans, useTranslation} from "react-i18next";
import getProjectPortalUrl from "../../app/helpers/getProjectPortalUrl";
import useLocale from "../../app/hooks/useLocale";

export default function AuditEditPage() {
    useUpdateStateByParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const name = useAuditEditSelector(state => selectChecklistVersion(state)?.name);
    const auditId = useAuditEditSelector(state => state.auditId);
    const step = useAuditEditSelector(state => state.step);
    const status = useAuditEditSelector(state => state.status[auditId ?? -1]);
    const completedAt = useAuditEditSelector(state => state.audits[auditId ?? -1]?.completedAt);
    const updateFailed = useAuditEditSelector(state => state.updateFailed);
    const locale = useLocale();
    const projectId = useProjectId();

    useEffect(() => {
        (async () => {
            if (!auditId) return;
            dispatch(fetchAudit({projectId, auditId}));
        })();
    }, [projectId, auditId, dispatch]);

    useEffect(() => {
        if (!auditId || status !== 'loaded') return;
        return syncAudit(projectId, auditId);
    }, [projectId, auditId, status]);

    if (!auditId) {
        return <NotFoundPage/>;
    }

    switch (status) {
        case 'forbidden':
            return <AccessDeniedPage/>;
        case 'notfound':
            return <NotFoundPage/>;
        case 'error':
            return <ErrorPage/>;
    }

    if (typeof name === "undefined") {
        return <Layout loading/>;
    }

    if (!step || !auditId) {
        return <RedirectToLastQuestion/>;
    }

    return (
        <Layout title={t('Checklist «{{name}}»', {name})} loading={status !== 'loaded'}>
            <div className="d-flex flex-column h-100 mt-3">
                <StepNav/>
                <Indicator/>
                <NavButtons/>
                <div className="flex-grow-1 border-top border-bottom pt-5 pb-5 position-relative">
                    {updateFailed && (
                        <div
                            className="alert alert-danger fw-bold">
                            {t('An error has occurred. The checklist could not be saved.')}
                            {updateFailed === 'forbidden' ?  <><br/><Trans
                                i18nKey="You do not have access to the checklists. Please start the app via the <1>web app project portal</1>.">
                                You do not have access to the checklists. Please start the app via the <a
                                href={getProjectPortalUrl(locale)}>web app project portal</a>
                            </Trans></> : null}
                        </div>
                    )}
                    {completedAt && (
                        <>
                            <div
                                className="alert alert-warning fw-bold">{t('This checklist is already completed.')}</div>
                            <div className="position-absolute w-100 h-100 top-0 start-0"/>
                        </>
                    )}
                    {(() => {
                        switch (step.name) {
                            case "question-group":
                                return <AnswerGroup auditId={auditId} questionGroupId={step.questionGroupId}/>
                            case "question":
                                return <Answer auditId={auditId} questionId={step.questionId}/>
                            case "completion":
                                return <Completion auditId={auditId}/>
                        }
                    })()}
                </div>
                <NavButtons/>
            </div>
        </Layout>
    );
}