import Question from "../../api/types/Question";
import AnswerRow from "../../api/types/AnswerRow";
import arrayReplace from "../../common/helpers/arrayReplace";
import AnswerColumnValueForm from "./AnswerColumnForm";
import styles from "./AnswerRowForm.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";

type Props = {
    answerRow: AnswerRow,
    onChange: (answerRow: AnswerRow) => void,
    onRemove: () => void,
    question: Question,
}

export default function AnswerRowForm({question, answerRow, onChange, onRemove}: Props) {
    const {t} = useTranslation();

    const widthTotal = question.columns.reduce((value, col) => value + col.width, 0);
    return (
        <div className={styles.wrapper}>
            <div className={styles.row}>
                {question.columns.map(questionColumn => {
                    const answerColumns = answerRow.columns.filter(column => column.questionColumnId === questionColumn.id);
                    const value = answerColumns.length && answerColumns[0].value ? answerColumns[0].value : null;
                    const id = 'answer-column-' + answerRow.identity + '-' + questionColumn.id;
                    const onValueChange = (value: string | null) => {
                        const columns = arrayReplace(answerRow.columns, {
                            questionColumnId: questionColumn.id,
                            value
                        }, 'questionColumnId', questionColumn.id);

                        return onChange({...answerRow, columns});
                    };
                    const width = (100 / widthTotal * questionColumn.width) + '%';

                    return (
                        <div key={questionColumn.id}
                             className={styles.column}
                             style={{width}}>
                            <label title={questionColumn.name}
                                   className={styles.label}
                                   htmlFor={id}>{questionColumn.name}</label>


                            <div className={styles.input}>
                                <AnswerColumnValueForm id={id} value={value} onChange={onValueChange}
                                                       questionColumn={questionColumn}/>

                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={styles.trash}>
                <button className="btn btn-link p-0">
                    <FontAwesomeIcon title={t('Remove entry')} icon={faTrash} onClick={onRemove}/>
                </button>
            </div>
        </div>
    );
}