import React from "react";
import {pull} from "lodash";
import {useTranslation} from "react-i18next";
import FormCheck from "../../common/components/FormCheck";
import Card from "../../common/components/Card";
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import selectAreaIdsFiltered from "../selectors/selectAreaIdsFiltered";
import removeUndefinedFromList from "../../common/helpers/removeUndefinedFromList";
import useQueryNumbers from "../../router/hooks/useQueryNumbers";
import useQueryValues from "../../router/hooks/useQueryValues";

export default function AreasFilter() {
    const {t} = useTranslation()
    const [areaIds, setAreaIds] = useQueryNumbers('area');
    const [languages] = useQueryValues('language');
    const [topicIds] = useQueryNumbers('topic');
    const filteredAreas = useAuditNewSelector(state => selectAreaIdsFiltered(state, {
        languages, topicIds
    }).flatMap(id => state.areas[id] ? state.areas[id] : []));

    return (
        <Card title={t('Areas')}>
            {removeUndefinedFromList(filteredAreas).map(area => (
                <FormCheck
                    key={area.id}
                    onChange={checked => setAreaIds(checked ? [...areaIds, area.id] : pull(areaIds, area.id))}
                    checked={areaIds.indexOf(area.id) !== -1}
                >
                    {area.name}
                </FormCheck>
            ))}
        </Card>
    );
}