import merge from "ts-deepmerge";
import {Draft} from "@reduxjs/toolkit";

export default function mergeDraftState<State>(state: Draft<State>, partialState: Partial<State>) {
    const nextState = merge.withOptions({mergeArrays: false}, state, partialState) as Draft<State>;
    const keys = Object.keys(state) as Array<keyof Draft<State>>;

    keys.forEach(function (key) {
        state[key] = nextState[key];
    })
}