import Layout from "./Layout";
import React from "react";
import {useTranslation} from "react-i18next";

export default function NotFoundPage() {
    const {t} = useTranslation();

    return (
        <Layout>
            {t('Page not found.')}
        </Layout>
    );
}