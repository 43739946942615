import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useMeasureListSelector from "../hooks/useMeasureListSelector";
import toggleDone from "../actions/toggleDone";
import useDispatch from "../../common/hooks/useDispatch";
import useProjectId from "../../app/hooks/useProjectId";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faUndo} from "@fortawesome/free-solid-svg-icons";
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";

type Props = {
    measureId: string,
}

export default function MeasureDoneToggle({measureId}: Props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState(false);
    const isDone = useMeasureListSelector(state => !!state.measures[measureId]?.controlDate)
    const {t} = useTranslation();
    const projectId = useProjectId();

    const onClick = async () => {
        setLoading(true);
        await dispatch(toggleDone({projectId, measureId, done: !isDone}));
        setLoading(false);
    }

    const label = isDone ? t('Revert') : t('Mark as Done');
    const icon = isDone ? faUndo : (hover ? faCheckSquare : faSquare);

    return (
        <button
            onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)}
            disabled={loading} className="btn btn-secondary text-nowrap text-decoration-none" onClick={onClick}>
            <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : icon}/> {label}
        </button>
    )

}