import AuditDetail from "../../api/types/AuditDetail";
import merge from "ts-deepmerge";
import normalizeAnswerGroup from "./normalizeAnswerGroup";
import undefinity from "../../common/helpers/undefinity";

export default function normalizeAuditDetail(audit: AuditDetail) {
    const {answerGroups, ...rest} = audit;

    return merge({
        audits: {
            [audit.id]: undefinity(rest)
        },
    }, ...answerGroups.map(answerGroup => normalizeAnswerGroup(answerGroup, audit.id)));
}