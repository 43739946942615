export default function arrayReplace<T>(items: T[] | undefined, item: T, fieldName: keyof T, fieldValue: any): T[] {
    let cloned = [...items || []];
    let added = false;

    for (const key in cloned) {
        if (cloned[key][fieldName] === fieldValue) {
            cloned[key] = item;
            added = true;
            break;
        }
    }

    if (!added) {
        cloned.push(item);
    }

    return cloned;
}