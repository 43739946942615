import denormalizeAnswerGroup from "../denormalizer/denormalizeAnswerGroup";
import denormalizeAnswer from "../denormalizer/denormalizeAnswer";
import {v4 as uuid} from "uuid";
import {auditEditActions} from "../auditEditSlice";
import {Dispatch, State} from "../../../store";

export default function setLastAnswers(auditId: string, questionGroupId: number) {
    return (dispatch: Dispatch, getState: () => State) => {
        const state = getState().auditEdit;
        const copyFromId = state.audits[auditId]?.copyFromId;
        const answerGroup = denormalizeAnswerGroup(state, auditId, questionGroupId);

        if (!answerGroup || !copyFromId) {
            return;
        }

        answerGroup.value = state.answerGroups[copyFromId]?.[questionGroupId]?.value ?? null;
        answerGroup.answers.forEach(answer => {
            const copyFromAnswer = denormalizeAnswer(state, copyFromId, answer.questionId);
            if (!copyFromAnswer) {
                return;
            }

            answer.value = copyFromAnswer.value;
            answer.measures = copyFromAnswer.measures.map(measure => ({...measure, id: uuid()}));
            answer.comments = copyFromAnswer.comments.map(comment => ({...comment, id: uuid()}));
            answer.photos = copyFromAnswer.photos;
        });

        dispatch(auditEditActions.setAnswerGroup({auditId, answerGroup}))
    }
}