import {OverlayTrigger, Popover} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";

type Props = {
    questionId: number
}

export default function QuestionPriority({questionId}: Props) {
    const {t} = useTranslation();
    const question = useAuditEditSelector(state => state.questions[questionId]);

    if (!question?.priority) {
        return null;
    }

    if (question?.priorityDescription) {
        return (
            <div className="mt-3 cursor-pointer">
                <OverlayTrigger
                    overlay={
                        <Popover id={"popover-" + question.id}>
                            <Popover.Body>
                                <div dangerouslySetInnerHTML={{__html: question.priorityDescription}}/>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <span>{t('Priority')}: {question.priority}</span>
                </OverlayTrigger>
            </div>
        )
    } else {
        return <div className="mt-3">{t('Priority')}: {question.priority}</div>
    }
}

