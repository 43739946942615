import React from "react";
import "./Indicator.css";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import selectCurrentGroupStep from "../selectors/selectCurrentGroupStep";
import selectQuestionSteps from "../selectors/selectQuestionSteps";
import IndicatorItem from "./IndicatorItem";
import getStepPath from "../helpers/getStepPath";

export default function Indicator() {
    const steps = useAuditEditSelector(state => {
        const groupStep = selectCurrentGroupStep(state);
        if (groupStep?.name !== 'question-group') {
            return [];
        }

        return selectQuestionSteps(state, groupStep.questionGroupId)
    })

    return (
        <nav aria-label="indicator" style={{minHeight: 40}}>
            <ol className="indicator mb-3">
                {steps.map(step => <IndicatorItem step={step} key={getStepPath(step)}/>)}
            </ol>
        </nav>
    )
}