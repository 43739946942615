import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import User from "../../api/types/User";

const fetchUsers = createAsyncThunk(
    'fetchUsers',
    async () => {
        return await request<User[]>('GET', '/api/users');
    }
);

export default fetchUsers;
