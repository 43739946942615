import React from "react";

type Props = {
    active?: boolean
    onClick?: () => void
    children?: any
};

export default function PaginationItem({children, active, onClick}: Props) {
    return (
        <li className={"page-item" + (active ? ' active' : '') + (!onClick ? ' disabled' : '')}>
            <button style={{width: 32}} className="page-link text-nowrap" onClick={onClick}>{children}</button>
        </li>
    );
}