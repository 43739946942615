import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useDispatch from "../../common/hooks/useDispatch";
import markNotificationAsRead from "../../users/actions/markAsRead";
import NotificationModel from "../../api/types/Notification";

export default function Notification(notification: NotificationModel) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const markAsRead = async () => {
        setLoading(true);
        await dispatch(markNotificationAsRead(notification.notificationId));
        setLoading(false);
    }

    return (
        <div key={notification.notificationId} className="alert alert-info mb-5" role="alert">
            <button disabled={loading} onClick={markAsRead}
                    className="btn btn-info notification-read float-end">
                {t('Mark as read')}
            </button>
            <strong>{notification.title}</strong>
            <div dangerouslySetInnerHTML={{__html: notification.html}}/>
        </div>
    )
}