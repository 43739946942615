import {createSelector} from "reselect";
import AuditEditState from "../types/AuditEditState";
import isStepEqual from "../helpers/isStepEqual";
import GroupStep from "../types/GroupStep";

const selectCurrentGroupStep = createSelector(
    (state: AuditEditState) => state.step,
    (state: AuditEditState) => state.questions,
    (step, questions): GroupStep | undefined => {
        let questionGroupId;
        switch (step?.name) {
            case "question-group":
                questionGroupId = step.questionGroupId;
                return {name: 'question-group', questionGroupId: questionGroupId};
            case "question":
                questionGroupId = questions[step.questionId]?.questionGroupId;
                if (!questionGroupId) return undefined;
                return {name: 'question-group', questionGroupId: questionGroupId};
            case "completion":
                return {name: 'completion'};
        }
    },
    {memoizeOptions: {resultEqualityCheck: isStepEqual}}
);

export default selectCurrentGroupStep;