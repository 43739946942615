import {createSelector} from 'reselect'
import selectSteps from "./selectSteps";
import stepsEqual from "../helpers/stepsEqual";
import GroupStep from "../types/GroupStep";

const selectGroupSteps = createSelector(
    selectSteps,
    (steps) => {
        return steps.filter(step => step.name !== 'question') as GroupStep[];
    },
    {memoizeOptions: {resultEqualityCheck: stepsEqual}}
);

export default selectGroupSteps;