import React from "react";
import UserSelect from "../../users/components/UserSelect";
import IntervalInputGroup from "../../common/components/IntervalInputGroup";
import FormCheck from "../../common/components/FormCheck";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {auditEditActions} from "../auditEditSlice";
import useDispatch from "../../common/hooks/useDispatch";

type Props = {
    auditId: string
}

export default function Repetition({auditId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const repetitionInterval = useAuditEditSelector(state => state.audits[auditId]?.repetitionInterval);
    const repetitionResponsible = useAuditEditSelector(state => state.audits[auditId]?.repetitionResponsible);
    const repetitionActive = useAuditEditSelector(state => state.audits[auditId]?.repetitionActive);
    const checklistVersion = useAuditEditSelector(state => state.checklistVersions[state.audits[auditId]?.checklistVersionId ?? -1]);

    if (!checklistVersion) {
        return null;
    }

    const onChange = (values: Parameters<typeof auditEditActions.updateAudit>[0]['values']) => {
        dispatch(auditEditActions.updateAudit({auditId, values}));
    }

    return (
        <div className="pb-3 mb-1">
            <FormCheck checked={repetitionActive || false}
                       onChange={repetitionActive => onChange({repetitionActive})}
            >
                {t('Activate the interval for rechecking.')}
                {checklistVersion.recommendedInterval && <> {t('Recommended interval')}: {checklistVersion.recommendedInterval}</>}
            </FormCheck>
            {
                repetitionActive && (
                    <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-end mt-3"
                         style={{maxWidth: 500, marginLeft: 20}}>
                        <div className="mb-3 me-4">
                            <label htmlFor="repetition-interval" className="text-nowrap">{t('Interval')}</label>
                            <IntervalInputGroup
                                id="repetition-interval"
                                value={repetitionInterval}
                                onChange={repetitionInterval => onChange({repetitionInterval})}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="repetition-responsible" className="text-nowrap">
                                {t('Person to notify')}
                            </label>
                            <UserSelect
                                id="repetition-responsible"
                                value={repetitionResponsible}
                                onChange={repetitionResponsible => onChange({repetitionResponsible})}/>
                        </div>
                    </div>
                )
            }
        </div>
    );
}