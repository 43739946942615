import selectChecklistIdsFiltered from "./selectChecklistIdsFiltered";
import AuditNewState from "../types/AuditNewState";
import Filter from "../types/Filter";
import createCachedSelector from "re-reselect";

const selectChecklistIdsFilteredPaginated = createCachedSelector(
    (state: AuditNewState, filter: Filter) => selectChecklistIdsFiltered(state, filter),
    (state: AuditNewState, filter: Filter, perPage: number) => perPage,
    (checklistIds, perPage) => {
        let pageIndex = 0;
        let itemsCount = 0;
        let checklistIdsPaginated: number[][] = [[]];

        checklistIds.forEach(checklistId => {
            if (itemsCount >= perPage) {
                pageIndex++;
                itemsCount = 0;
                checklistIdsPaginated.push([]);
            }

            checklistIdsPaginated[pageIndex].push(checklistId);
            itemsCount += 1;
        })

        return checklistIdsPaginated;
    }
)(
    {
        keySelector: (state, filter, perPage) => {
            return JSON.stringify({filter, perPage});
        }
    }
);

export default selectChecklistIdsFilteredPaginated;