import Measure from "../../api/types/Measure";
import {v4 as uuid} from "uuid";
import PredefinedMeasure from "../../api/types/PredefinedMeasure";

export default function createMeasure(predefinedMeasure?: PredefinedMeasure): Measure {
    return {
        id: uuid(),
        measure: predefinedMeasure?.measure || '',
        dueDate: null,
        responsible: null,
        inspector: null,
        controlDate: null
    }
};