import {Button, Modal, OverlayTrigger, Popover} from "react-bootstrap";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ResponsiblePerson from "../../api/types/ResponsiblePerson";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import ResponsiblePersonForm from "../../audit-new/compoenents/ResponsiblePersonForm";
import styles from "./AuditEditModal.module.css";
import selectChecklistVersion from "../selectors/selectChecklistVersion";
import useDispatch from "../../common/hooks/useDispatch";
import {auditEditActions} from "../auditEditSlice";

type Props = {
    auditId: string
}

export default function AuditEditModal({auditId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const name = useAuditEditSelector(state => selectChecklistVersion(state)?.name);
    const auditResponsiblePerson = useAuditEditSelector(state => state.audits[auditId]?.responsiblePerson);
    const completedAt = useAuditEditSelector(state => state.audits[auditId]?.completedAt);
    const auditReason = useAuditEditSelector(state => state.audits[auditId]?.reason);
    const [reason, setReason] = useState('');
    const [responsiblePerson, setResponsiblePerson] = useState<Partial<ResponsiblePerson>>({})
    const [isValidated, setIsValidated] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const responsiblePersonRequired = !!auditResponsiblePerson;

    const open = () => {
        setIsValidated(false);
        setOpen(true);
        setReason(auditReason ?? '');
        setResponsiblePerson(auditResponsiblePerson ?? {});
    }

    const close = () => {
        setOpen(false);
    }

    const update = async () => {
        setIsValidated(true);

        if (responsiblePersonRequired) {
            if (!responsiblePerson.name || !responsiblePerson.phone || !responsiblePerson.email) {
                return;
            }
        }

        dispatch(auditEditActions.updateAudit({
            auditId,
            values: {
                reason: reason,
                responsiblePerson: responsiblePersonRequired ? responsiblePerson as ResponsiblePerson : undefined
            }
        }))

        setOpen(false);
    }

    if (completedAt) {
        return null;
    }

    return (
        <>
            <OverlayTrigger overlay={<Popover><Popover.Body>{t('Edit checklist')}</Popover.Body></Popover>}>
                <button onClick={open} className="btn btn-secondary me-2">
                    <FontAwesomeIcon icon={faEdit}/>
                </button>
            </OverlayTrigger>
            <Modal show={isOpen} onHide={close}
                   dialogClassName={responsiblePersonRequired ? styles.modalDialog : undefined}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Checklist «{{name}}»', {name})}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={update}>
                        <div className={isValidated ? "was-validated" : undefined}>
                            {responsiblePersonRequired &&
                            <ResponsiblePersonForm value={responsiblePerson} onChange={setResponsiblePerson}/>}
                        </div>

                        <div className="mb-3">
                            <label
                                htmlFor={"reason" + auditId}>{t('What is the checklist filled out for?')}</label>
                            <textarea value={reason} onChange={e => setReason(e.target.value)}
                                      className="form-control" id={"reason" + +auditId} rows={3}/>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={update}>
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}