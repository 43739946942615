import React, {ReactNode, useState} from "react";

type Props = {
    checked: boolean
    onChange: (checked: boolean) => void
    children: ReactNode
    inline?: boolean
};

let COUNT = 0;

export default function FormCheck({checked, onChange, children, inline}: Props) {
    const [id] = useState<string>('form-check-' + (COUNT++))

    return (
        <div className={"form-check" + (inline ? ' form-check-inline' : '')}>
            <input onChange={e => onChange(e.target.checked)}
                   className="form-check-input"
                   type="checkbox" id={id}
                   checked={checked}
            />
            <label className="form-check-label" htmlFor={id}>
                {children}
            </label>
        </div>
    );
}