import React from "react";
import useAuditListSelector from "../hooks/useAuditEditSelector";
import selectAuditsByChecklistId from "../selectors/selectAuditsByChecklistId";
import AuditListRow from "./AuditListRow";
import selectChecklistNameById from "../selectors/selectChecklistNameById";

type Props = {
    checklistId: number
}

export default function AuditListRows({checklistId}: Props) {
    const checklistName = useAuditListSelector(state => selectChecklistNameById(state, checklistId));
    const audits = useAuditListSelector(state => selectAuditsByChecklistId(state, checklistId))

    return (
        <>
            <tr className="bg-light small">
                <th colSpan={5} className="pt-2 pb-2">{checklistName}</th>
            </tr>
            {audits.map(audit => (
                <AuditListRow checklistId={checklistId} audit={audit} key={audit.id}/>
            ))}
        </>
    );
}