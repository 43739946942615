import React from "react";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {auditEditActions} from "../auditEditSlice";
import AnswerGroupLastAnswers from "./AnswerGroupLastAnswers";
import RedirectToNextStep from "./RedirectToNextStep";

type Props = {
    auditId: string
    questionGroupId: number
}

function AnswerGroup({auditId, questionGroupId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const value = useAuditEditSelector(state => state.answerGroups[auditId]?.[questionGroupId]?.value);
    const questionGroup = useAuditEditSelector(state => state.questionGroups[questionGroupId]);

    const onChange = (value: boolean) => {
        dispatch(auditEditActions.setAnswerGroupValue({auditId, questionGroupId, value}))
    }

    if (!questionGroup) {
        return null;
    }

    if (questionGroup.parentTitle) {
        return <RedirectToNextStep/>;
    }

    return (
        <>
            <AnswerGroupLastAnswers auditId={auditId} questionGroupId={questionGroupId}/>

            <h5 className="mb-4">{questionGroup.title}</h5>
            <div className="mb-4" dangerouslySetInnerHTML={{__html: questionGroup.description}}/>

            {questionGroup.question ? (
                <>
                    <h6 className="mb-4">{questionGroup.question}</h6>
                    <div className="ms-3">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id={"answerGroupYes" + questionGroup.id}
                                   onChange={e => onChange(e.target.checked)}
                                   checked={value === true}
                            />
                            <label className="form-check-label"
                                   htmlFor={"answerGroupYes" + questionGroup.id}>{t('Yes')}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id={"answerGroupNo" + questionGroup.id}
                                   checked={value === false}
                                   onChange={e => onChange(!e.target.checked)}
                            />
                            <label className="form-check-label"
                                   htmlFor={"answerGroupNo" + questionGroup.id}>{t('No')}</label>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default React.memo(AnswerGroup);