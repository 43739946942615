import {configureStore} from '@reduxjs/toolkit';
import {auditEditReducer} from "./packages/audit-edit/auditEditSlice";
import {usersReducer} from "./packages/users/usersSlice";
import {auditListReducer} from "./packages/audit-list/auditListSlice";
import {auditNewReducer} from "./packages/audit-new/auditNewSlice";
import {measureListReducer} from "./packages/measure-list/measureListSlice";
import {appReducer} from "./packages/app/appSlice";

export const store = configureStore({
    reducer: {
        measureList: measureListReducer,
        auditNew: auditNewReducer,
        auditList: auditListReducer,
        auditEdit: auditEditReducer,
        users: usersReducer,
        app: appReducer,
    },
    devTools: process.env.NODE_ENV === 'development'
});

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;