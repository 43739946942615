import React, {ReactNode, useCallback, useEffect} from "react";
import styles from "./Layout.module.css";
import logo from "../assets/images/wdg_logo_de.svg"
import logoSw from "../assets/images/logo_sw.svg";
import {Link, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useBaseUrl from "../hooks/useBaseUrl";
import {supportedLanguages} from "../../../i18n";
import moment from "moment";
import displayLanguage from "../../common/helpers/displayLanguage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import useSelector from "../../common/hooks/useSelector";
import fetchProject from "../actions/fetchProject";
import useDispatch from "../../common/hooks/useDispatch";
import getShopUrl from "../helpers/getShopUrl";
import Notifications from "./Notifications";

type Props = {
    children?: ReactNode
    title?: ReactNode
    loading?: boolean
}

export default function Layout({children, title, loading}: Props) {
    const {t} = useTranslation();
    const baseUrl = useBaseUrl();
    const {locale, projectId} = useParams<{ locale?: string, projectId?: string }>();
    const history = useHistory();
    const {i18n} = useTranslation();
    const currentProjectId = useSelector(state => state.app.currentProjectId);
    const project = useSelector(state => state.app.project);
    const dispatch = useDispatch();
    const pushLanguage = useCallback((language: string) => {
        history.push('/' + language + history.location.pathname.substr(language.length + 1));
    }, [history]);

    useEffect(() => {
        if (projectId && currentProjectId !== projectId) {
            dispatch(fetchProject(projectId));
        }

    }, [dispatch, projectId, currentProjectId])

    useEffect(() => {
        if (!locale || i18n.language === locale) {
            return;
        }

        if (supportedLanguages.includes(locale)) {
            moment.locale(locale);
            i18n.changeLanguage(locale);
        } else {
            pushLanguage(i18n.language);
        }
    }, [pushLanguage, i18n, locale])

    return (
        <div className={"d-flex flex-column h-100 " + (project?.demo ? styles.demo : '')}>
            <div className={styles.header}>
                <div className="container">
                    <div className="d-flex">
                        <img className="ms-0 mt-4 mb-3" alt="logo" src={logo}/>

                        <div className="ms-auto mt-4">
                            <div className={styles.languageChange}>
                                <select className="form-select" value={locale} onChange={e => {
                                    pushLanguage(e.target.value);
                                }}>
                                    {supportedLanguages.map(language => (
                                        <option key={language}
                                                value={language}>{displayLanguage(language, language)}</option>
                                    ))}
                                </select>
                                <FontAwesomeIcon className="icon" icon={faAngleDown}/>
                            </div>
                            <br className="clearfix"/>
                            <nav className={styles.nav}>
                                <ul>
                                    <li>
                                        <Link to={`${baseUrl}/`}>{t('Checklists')}</Link>
                                    </li>
                                    <li>
                                        <Link to={`${baseUrl}/measures`}>{t('Measures')}</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="d-flex align-items-end">
                        <h1 className="mb-4">{title ? title : t('Checklist - Web App')}</h1>
                        {project?.demo && <div className="mb-4 ms-3">
                            <span className="small badge bg-danger text-white">Demo</span>
                            <a target="_blank" rel="noreferrer" href={getShopUrl(locale ?? 'de')} className="ms-2 badge bg-primary text-white">Shop</a>
                        </div>}
                    </div>
                </div>
            </div>
            <div className={"container flex-grow-1 position-relative " + styles.content}>
                {loading && (
                    <div className="spinner-border-sm spinner-border position-absolute" style={{top: -40, right: 20}}
                         role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                <Notifications/>
                {children}
            </div>
            <div className={styles.footer + ' mt-5'}>
                <div className="container">
                    <div className="float-end d-flex align-items-start">
                        <address className="me-5">
                            {t('suissetec Address.0')}<br/>
                            {t('suissetec Address.1')}<br/>
                            {t('suissetec Address.2')}<br/>
                            {t('suissetec Address.3')}<br/>
                            {t('suissetec Address.4')}
                        </address>
                        <img alt="logo" src={logoSw}/>
                    </div>
                </div>
            </div>
        </div>
    )
}