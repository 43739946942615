import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import MeasureDetail from "../../api/types/MeasureDetail";

const fetchMeasures = createAsyncThunk(
    'fetchMeasures',
    async ({projectId, locale}: { projectId: string, locale: string }) => {
        const measures = await request<MeasureDetail[]>('GET', `/api/projects/${projectId}/measures?locale=${locale}`);
        return {measures};
    }
);

export default fetchMeasures;