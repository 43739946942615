import {createSelector} from "reselect";
import MeasureListState from "../types/MeasureListState";
import MeasureDetail from "../../api/types/MeasureDetail";

const selectAuditData = createSelector(
    (state: MeasureListState) => state.measureIds,
    (state: MeasureListState) => state.measures,
    (measureIds, measures) => {
        let auditIds: string[] = [];
        let auditData: { [key: string]: MeasureDetail['audit'] | undefined } = {};
        measureIds?.forEach(measureId => {
            const measure = measures[measureId];

            if (measure && measure.audit && !auditIds.includes(measure.audit.id)) {
                auditIds.push(measure.audit.id);
                auditData[measure.audit.id] = measure.audit;
            }
        });

        return {auditIds, auditData};
    }
);

export default selectAuditData;