import {createCachedSelector} from 're-reselect';
import selectSteps from "./selectSteps";
import AuditEditState from "../types/AuditEditState";
import {createSelectorCreator, defaultMemoize} from "reselect";
import stepsEqual from "../helpers/stepsEqual";
import QuestionStep from "../types/QuestionStep";

const selectQuestionSteps = createCachedSelector(
    selectSteps,
    (steps: AuditEditState, questionGroupId: number | 'All') => questionGroupId,
    (steps, questionGroupId) => {
        let questionSteps: QuestionStep[] = [];
        let id: number;
        steps.forEach(step => {
            if (step.name === "question-group") {
                id = step.questionGroupId;
            } else if (step.name === "question" && (id === questionGroupId || typeof questionGroupId === "undefined")) {
                questionSteps.push(step);
            }
        })

        return questionSteps;
    }
)({
    selectorCreator: createSelectorCreator(defaultMemoize, {resultEqualityCheck: stepsEqual}),
    keySelector: (state, questionGroupId) => questionGroupId
});

export default selectQuestionSteps;