import Question from "../../api/types/Question";
import AnswerLine from "../../api/types/AnswerLine";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import NumberInput from "../../common/components/NumberInput";
import {useTranslation} from "react-i18next";

type Props = {
    answerLine: AnswerLine,
    onChange: (answerLine: AnswerLine) => void,
    onRemove: () => void,
    question: Question,
}

export default function AnswerLineForm({question, answerLine, onChange, onRemove}: Props) {
    const {t} = useTranslation();

    return (
        <div className="d-flex mb-3 align-items-start" style={{maxWidth: 700}}>
            <input type="text" className="form-control me-3"
                   placeholder={t('Description')}
                   value={typeof answerLine.label === 'string' ? answerLine.label : ''}
                   onChange={e => onChange({...answerLine, label: e.target.value})}
            />

            <NumberInput
                id={"question" + question.id + "-" + answerLine.identity}
                value={typeof answerLine.value === "number" ? answerLine.value : undefined}
                onChange={value =>
                    onChange({...answerLine, value: typeof value !== "number" ? null : value})
                }
                min={question.valueMin}
                max={question.valueMax}
                unit={question.unit}
            />

            <button className="btn btn-link p-0 ms-3 mt-2">
                <FontAwesomeIcon title={t('Remove entry')} icon={faTrash} onClick={onRemove}/>
            </button>
        </div>
    );
}