import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import AuditDetail from "../../api/types/AuditDetail";
import uploadPhoto from "../helpers/uploadPhoto";

const updateAudit = createAsyncThunk(
    'updateAudit',
    async ({projectId, audit}: { projectId: string, audit: AuditDetail }) => {
        await uploadPhoto(audit);
        await request('PUT', `/api/projects/${projectId}/audits/${audit.id}`, audit);
    }
);

export default updateAudit;