import {createSelector} from 'reselect'
import AuditEditState from "../types/AuditEditState";
import Step from "../types/Step";
import stepsEqual from "../helpers/stepsEqual";
import selectChecklistVersion from "./selectChecklistVersion";

const selectAllSteps = createSelector(
    (state: AuditEditState) => selectChecklistVersion(state)?.questionGroupIds,
    (state: AuditEditState) => state.questionGroups,
    (state: AuditEditState) => state.questions,
    (questionGroupIds, questionGroups, questions) => {
        let steps: Step[] = [];
        questionGroupIds?.forEach(questionGroupId => {
            const questionGroup = questionGroups[questionGroupId];
            if (!questionGroup) return;

            steps.push({name: 'question-group', questionGroupId, parent: questionGroup.parentTitle});
            questionGroup.questionIds.forEach(questionId => {
                const question = questions[questionId];
                if (!question) return;

                steps.push({name: 'question', questionId});
            })
        })

        steps.push({name: "completion"});

        return steps;
    },
    {memoizeOptions: {resultEqualityCheck: stepsEqual}}
);

export default selectAllSteps;