import {useParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import Step from "../types/Step";
import {useDispatch} from "react-redux";
import useAuditEditSelector from "./useAuditEditSelector";
import getStepPath from "../helpers/getStepPath";
import {auditEditActions} from "../auditEditSlice";

export default function useUpdateStateByParams() {
    const dispatch = useDispatch();
    const currentStep = useAuditEditSelector(state => state.step);
    const currentAuditId = useAuditEditSelector(state => state.auditId);
    const {auditId, stepName, stepRefId} = useParams<{ auditId?: string, stepName?: string, stepRefId?: string }>();
    const paramsStep = useMemo((): Step | undefined => {
        switch (stepName) {
            case "completion":
                return {name: "completion"};
            case "question-group":
                return {name: "question-group", questionGroupId: parseInt(stepRefId || '')};
            case "question":
                return {name: "question", questionId: parseInt(stepRefId || '')};
            default:
                return undefined;
        }
    }, [stepName, stepRefId]);

    useEffect(() => {
        const paramsStepKey = paramsStep ? getStepPath(paramsStep) : undefined;
        const currentStepKey = currentStep ? getStepPath(currentStep) : undefined;
        const paramsAuditId = auditId;

        if (paramsStepKey !== currentStepKey || currentAuditId !== paramsAuditId) {
            dispatch(auditEditActions.updateByParams({step: paramsStep, auditId: paramsAuditId}));
        }
    }, [dispatch, paramsStep, currentStep, currentAuditId, auditId]);
}

