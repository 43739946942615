import createCachedSelector from "re-reselect";
import Filter from "../types/Filter";
import MeasureListState from "../types/MeasureListState";

const selectMeasureIdsFiltered = createCachedSelector(
    (state: MeasureListState) => state.measures,
    (state: MeasureListState) => state.measureIds,
    (state: MeasureListState, filter: Filter) => filter,
    (measures, measureIds, filter) => {
        return measureIds?.filter(measureId => {
            const {responsible, showDone, auditId} = filter;
            const measure = measures[measureId];
            if (!measure) return false;

            if (responsible && measure.responsible !== responsible) {
                return false;
            }

            if (auditId && measure.audit?.id !== auditId) {
                return false;
            }

            return !measure.controlDate || showDone;
        }) ?? [];
    }
)({
    keySelector: (state, filter) => JSON.stringify(filter)
});

export default selectMeasureIdsFiltered;