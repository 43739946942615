import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight, faCopy} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useParams} from "react-router-dom";
import request from "../../api/helpers/request";
import Audit from "../../api/types/Audit";
import useDisplayLanguage from "../../common/hooks/useDisplayLanguage";
import {useTranslation} from "react-i18next";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import useProjectId from "../../app/hooks/useProjectId";
import ResponsiblePerson from "../../api/types/ResponsiblePerson";
import ResponsiblePersonForm from "./ResponsiblePersonForm";
import styles from "./ChecklistStartButton.module.css";

type Props = {
    checklistId: number
    copyFromAudit?: Audit
}

export default function ChecklistStartButton({checklistId, copyFromAudit}: Props) {
    const {locale = 'de'} = useParams<{ locale?: string }>()
    const displayLanguage = useDisplayLanguage();
    const checklist = useAuditNewSelector(state => state.checklists[checklistId]);
    const {t} = useTranslation();
    const history = useHistory();
    const [language, setLanguage] = useState(checklist?.languages.includes(locale) ? locale : checklist?.languages?.[0]);
    const [reason, setReason] = useState(copyFromAudit?.reason ?? '');
    const [responsiblePerson, setResponsiblePerson] = useState<Partial<ResponsiblePerson>>(copyFromAudit?.responsiblePerson || {})
    const [creating, setCreating] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [open, setOpen] = useState(false);
    const baseUrl = useBaseUrl();
    const projectId = useProjectId();
    const responsiblePersonRequired = copyFromAudit ? !!copyFromAudit.responsiblePerson : checklist?.responsiblePersonRequired;
    const name = copyFromAudit ? copyFromAudit.checklist?.name : checklist?.name;
    const languages = copyFromAudit ? copyFromAudit.checklist?.languages : checklist?.languages;
    const id = copyFromAudit ? copyFromAudit.id : checklist?.id;

    if (!checklist && !copyFromAudit) {
        return null;
    }

    const close = () => {
        setIsValidated(false);
        setCreating(false);
        setOpen(false);
    }

    const create = async () => {
        setIsValidated(true);

        if (responsiblePersonRequired) {
            if (!responsiblePerson.name || !responsiblePerson.phone || !responsiblePerson.email) {
                return;
            }
        }

        setCreating(true);

        const {id, ...responsiblePersonNew} = responsiblePerson;

        const audit = await request<Audit>('POST', `/api/projects/${projectId}/audits`, {
            checklistVersionId: copyFromAudit ? undefined : checklist?.currentVersionId,
            language,
            reason,
            responsiblePerson: responsiblePersonRequired ? responsiblePersonNew : undefined,
            copyFromId: copyFromAudit?.id,
        });

        history.push(`${baseUrl}/audits/${audit.id}`);
    }

    return (
        <>
            <button className="btn btn-link text-nowrap btn-sm p-0 me-2" onClick={() => setOpen(true)}>
                {copyFromAudit ? <FontAwesomeIcon title={t('Copy checklist')} icon={faCopy}/> :
                    <>
                        {t('Start')} <FontAwesomeIcon icon={faArrowCircleRight}/>
                    </>
                }
            </button>
            <Modal show={open} onHide={close}
                   dialogClassName={responsiblePersonRequired ? styles.modalDialog : undefined}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Checklist «{{name}}»', {name: name})}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={create}>
                        <div className="mb-3">
                            <label htmlFor={"language" + id}>{t('Language')}</label>
                            <select disabled={creating} className="form-select" id={"language" + id}
                                    value={language} onChange={e => setLanguage(e.target.value)}>
                                {languages?.map(language => (
                                    <option key={language} value={language}>{displayLanguage(language)}</option>
                                ))}
                            </select>
                        </div>

                        <div className={isValidated ? "was-validated" : undefined}>
                            {responsiblePersonRequired &&
                            <ResponsiblePersonForm value={responsiblePerson} onChange={setResponsiblePerson}/>}
                        </div>

                        <div className="mb-3">
                            <label
                                htmlFor={"reason" + id}>{t('What is the checklist filled out for?')}</label>
                            <textarea disabled={creating} value={reason} onChange={e => setReason(e.target.value)}
                                      className="form-control" id={"reason" + id} rows={3}/>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button disabled={creating} variant="primary" onClick={create}>
                        {t('Start')} <span className="ps-2"><FontAwesomeIcon icon={faArrowCircleRight}/></span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}