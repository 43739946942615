import React, {ReactNode} from "react";

type Props = {
    title: ReactNode
    children: ReactNode
}

export default function Card({title, children}: Props) {
    return (
        <div className="card">
            <div className="card-header">
                {title}
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
}