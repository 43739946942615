import {createSelector} from "reselect";
import AuditListState from "../types/AuditListState";
import selectGroupedByChecklistData from "./selectGroupedByChecklistData";

const selectChecklistIdsPaginated = createSelector(
    selectGroupedByChecklistData,
    (state: AuditListState, perPage: number) => perPage,
    ({checklistIds, groupedByChecklist}, perPage) => {
        let pageIndex = 0;
        let itemsCount = 0;
        let checklistIdsPaginated: number[][] = [[]];

        checklistIds.forEach(checklistId => {
            if (itemsCount >= perPage) {
                pageIndex++;
                itemsCount = 0;
                checklistIdsPaginated.push([]);
            }

            checklistIdsPaginated[pageIndex].push(checklistId);
            itemsCount += groupedByChecklist[checklistId]?.length ?? 0;
        })

        return checklistIdsPaginated;
    }
);

export default selectChecklistIdsPaginated;