import Answer from "../../api/types/Answer";
import undefinity from "../../common/helpers/undefinity";
import normalizePhoto from "./normalizePhoto";
import merge from "ts-deepmerge";
import normalizeMeasure from "./normalizeMeasure";
import normalizeComment from "./normalizeComment";

export default function normalizeAnswer(answer: Answer, auditId: string) {
    const {measures, photos, comments, ...rest} = answer;

    return merge({
        answers: {
            [auditId]: undefinity({
                [answer.questionId]: undefinity({
                    ...rest,
                    measureIds: measures.map(measure => measure.id),
                    photoIds: photos.map(photo => photo.id),
                    commentIds: comments.map(comment => comment.id)
                })
            }),
        }
    }, ...photos.map(normalizePhoto), ...measures.map(normalizeMeasure), ...comments.map(normalizeComment))
}