import Step from "../types/Step";
import useAuditEditSelector from "./useAuditEditSelector";
import getStepPath from "../helpers/getStepPath";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import useQueryFlag from "../../router/hooks/useQueryFlag";

export default function useStepPath(step?: Step): string {
    const baseUrl = useBaseUrl();
    const auditId = useAuditEditSelector(state => state.auditId);
    const [collapsed] = useQueryFlag('collapsed');

    if (!step) {
        return '';
    }

    return `${baseUrl}/audits/${auditId}/${getStepPath(step)}` + (collapsed ? '?collapsed=1' : '');
}