import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import Checklist from "../../api/types/Checklist";
import Area from "../../api/types/Area";
import Topic from "../../api/types/Topic";

const fetchChecklists = createAsyncThunk(
    'fetchChecklists',
    async (locale: string) => {
        const checklists = await request<Checklist[]>('GET', `/api/checklists?locale=${locale}`);
        const areas = await request<Area[]>('GET', `/api/areas?locale=${locale}`);
        const topics = await request<Topic[]>('GET', `/api/topics?locale=${locale}`);

        return {checklists, areas, topics};
    }
);

export default fetchChecklists;