import createCachedSelector from "re-reselect";
import AuditNewState from "../types/AuditNewState";
import Filter from "../types/Filter";
import selectChecklistIdsFiltered from "./selectChecklistIdsFiltered";

const selectAreaIdsFiltered = createCachedSelector(
    (state: AuditNewState, filter: Filter) => selectChecklistIdsFiltered(state, filter),
    (state: AuditNewState) => state.checklists,
    (state: AuditNewState) => state.areaIds,
    (checklistIds, checklists, areaIds) => {
        return areaIds.filter(areaId => {
            return checklistIds.some(checklistId => {
                return checklists[checklistId]?.areaIds.includes(areaId);
            })
        });
    }
)({
    keySelector: (state, filter) => JSON.stringify(filter)
});

export default selectAreaIdsFiltered;