import Step from "../types/Step";

export default function getStepPath(step: Step): string {
    switch (step.name) {
        case "question":
            return step.name + '/' + step.questionId;
        case "question-group":
            return step.name + '/' + step.questionGroupId;
        case "completion":
            return step.name;
    }
}