import useQueryValue from "./useQueryValue";
import {useCallback} from "react";

export default function useQueryFlag(name: string, defaultValue: boolean | undefined = undefined): [boolean | undefined, (value: boolean | undefined) => void] {
    const toString = (value: boolean | undefined): string => {
        if (typeof value === 'undefined') {
            return '';
        }

        return value ? '1' : '0';
    }

    const fromString = (value: string): boolean | undefined => {
        if (value === '') {
            return undefined;
        }

        return value === '1';
    }

    const [value, setValue] = useQueryValue(name, toString(defaultValue));

    const setBoolean = useCallback((value: boolean | undefined) => {
        setValue(toString(value));
    }, [setValue]);

    return [fromString(value), setBoolean];
}