import {createSelector} from 'reselect'
import AuditEditState from "../types/AuditEditState";
import findStep from "../helpers/findStep";
import selectSteps from "./selectSteps";
import isStepEqual from "../helpers/isStepEqual";

const selectNextStep = createSelector(
    selectSteps,
    (state: AuditEditState) => state.step,
    (steps, currentStep) => {
        return currentStep ? findStep(steps, currentStep, 1) : undefined;
    },
    {memoizeOptions: {resultEqualityCheck: isStepEqual}}
);

export default selectNextStep;