import {createSlice} from "@reduxjs/toolkit";
import UsersState from "./types/UsersState";
import fetchUsers from "./actions/fetchUsers";
import fetchMe from "./actions/fetchMe";
import fetchNotifications from "./actions/fetchNotifications";
import markAsRead from "./actions/markAsRead";

const initialState: UsersState = {users: {}, userIds: [], notifications: {}};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMe.fulfilled, (state, {payload: me}) => {
            state.me = me;
        });
        builder.addCase(fetchNotifications.fulfilled, (state, {payload: notifications, meta}) => {
            state.notifications[meta.arg] = notifications;
        });
        builder.addCase(markAsRead.fulfilled, (state, {meta}) => {
            for (const locale in state.notifications) {
                if (state.notifications.hasOwnProperty(locale)) {
                    state.notifications[locale] = state.notifications[locale]?.filter(notification => {
                        return notification.notificationId !== meta.arg
                    });
                }
            }
        });
        builder
            .addCase(fetchUsers.pending, state => {
                state.state = 'pending';
            })
            .addCase(fetchUsers.fulfilled, (state, {payload: users}) => {
                state.state = 'loaded';
                state.userIds = users.map(user => {
                    state.users[user.id] = user;
                    return user.id;
                })
            });
    },
});

export const usersReducer = usersSlice.reducer;