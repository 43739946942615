import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {useDispatch} from "react-redux";
import {auditEditActions} from "../auditEditSlice";
import UserSelect from "../../users/components/UserSelect";

type Props = {
    auditId: string
    questionId: number
    measureId: string
}

export default function Measure({auditId, questionId, measureId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const measure = useAuditEditSelector(state => state.measures[measureId])

    if (!measure) {
        return null;
    }

    const onRemove = () => {
        dispatch(auditEditActions.removeMeasure({auditId, questionId, measureId}));
    }

    const onChange = (values: Parameters<typeof auditEditActions.updateMeasure>[0]["values"]) => {
        dispatch(auditEditActions.updateMeasure({measureId, values}));
    }

    return (
        <div className="d-flex mb-3 w-100">
             <textarea
                 onChange={e => onChange({measure: e.target.value})}
                 className={"form-control flex-grow-1 me-3 " + (!measure.measure ? ' is-invalid' : undefined)}
                 value={measure.measure}
             />
            <div className="me-3 d-flex flex-column" style={{width: 170}}>
                <label htmlFor={"measure-date" + measure.id}>{t('Due date')}</label>
                <input type="date"
                       id={"measure-date" + measure.id}
                       className={'form-control mb-3 ' + (!measure.dueDate ? ' is-invalid' : undefined)}
                       value={measure.dueDate || ''}
                       onChange={e => onChange({dueDate: e.target.value || null})}/>
                <label htmlFor={"measure-responsible" + measure.id}>{t('Responsible')}</label>
                <UserSelect
                    className={!measure.responsible ? 'is-invalid' : undefined}
                    value={measure.responsible || ''}
                    onChange={value => onChange({responsible: value || null})}/>
            </div>
            <button className="btn btn-link p-0" onClick={onRemove}>
                <FontAwesomeIcon title={t('Delete measure')} icon={faTrash}/>
            </button>
        </div>
    );
}