import {createSlice} from "@reduxjs/toolkit";
import fetchProject from "./actions/fetchProject";
import createAppState from "./factories/createAppState";

const appSlice = createSlice({
    name: 'app',
    initialState: createAppState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProject.pending, (state, {meta}) => {
                state.currentProjectId = meta.arg;
            })
            .addCase(fetchProject.fulfilled, (state, {payload}) => {
                state.project = payload;
            });
    },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;

