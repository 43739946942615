import React from "react";
import PaginationItem from "./PaginatonItem";

type Props = {
    pages: number,
    page: number,
    setPage: (page: number) => void
};

export default function Pagination({pages, page, setPage}: Props) {
    if (pages <= 1) {
        return null;
    }

    const max = 8;

    let start = 1;
    let left = pages;

    if (pages > max) {
        if (page < max / 2) {
            left = max - 2;
        } else if (page > pages - max / 2 + 1) {
            left = max - 2;
            start = Math.min(page - Math.floor(max / 2), pages - left + 1);
        } else {
            start = page - Math.floor(max / 2) + 2;
            left = max - 4;
        }
    }

    return (
        <nav aria-label="navigation">
            <ul className="pagination pagination-sm float-end">
                {pages > max && start > 1 && <PaginationItem onClick={() => setPage(1)}>1</PaginationItem>}
                {pages > max && start === 3 && <PaginationItem onClick={() => setPage(2)}>2</PaginationItem>}
                {pages > max && start > 3 && <PaginationItem>...</PaginationItem>}
                {Array.from(Array(left).keys()).map(i => (
                    <PaginationItem key={i} active={i + start === page} onClick={() => setPage(i + start)}>
                        {i + start}
                    </PaginationItem>
                ))}
                {start + left < pages - 1 && <PaginationItem>...</PaginationItem>}
                {start + left === pages - 1 &&
                <PaginationItem onClick={() => setPage(pages - 1)}>{pages - 1}</PaginationItem>}
                {start + left < pages + 1 && <PaginationItem onClick={() => setPage(pages)}>{pages}</PaginationItem>}
            </ul>
        </nav>

    );
}