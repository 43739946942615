import React, {useRef} from "react";
import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {auditEditActions} from "../auditEditSlice";

type Props = {
    auditId: string,
    questionId: number
}

export default function PhotoAddButton({auditId, questionId}: Props) {
    const input = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const addPhoto = (file: File) => {
        dispatch(auditEditActions.addPhoto({
            auditId, questionId, photo: {
                id: uuid(),
                url: URL.createObjectURL(file)
            }
        }))
    }

    return (
        <>
            <button onClick={() => input.current && input.current.click()} className="btn btn-link p-0">
                {t('Add photo')}
            </button>
            <input
                className="invisible position-absolute" type="file" ref={input}
                onChange={e => e.target.files && addPhoto(e.target.files[0])}
                accept="image/*"
            />
        </>
    );
}