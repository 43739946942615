import React from "react";
import {pull} from "lodash";
import {useTranslation} from "react-i18next";
import FormCheck from "../../common/components/FormCheck";
import Card from "../../common/components/Card";
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import useDisplayLanguage from "../../common/hooks/useDisplayLanguage";
import selectLanguagesFiltered from "../selectors/selectLanguagesFiltered";
import useQueryNumbers from "../../router/hooks/useQueryNumbers";
import useQueryValues from "../../router/hooks/useQueryValues";

export default function LanguagesFilter() {
    const displayLanguage = useDisplayLanguage();
    const {t} = useTranslation()
    const [languages, setLanguages] = useQueryValues('language');
    const [topicIds] = useQueryNumbers('topic');
    const [areaIds] = useQueryNumbers('area');
    const filteredLanguages = useAuditNewSelector(
        state => selectLanguagesFiltered(state, {topicIds, areaIds})
    );

    return (
        <Card title={t('Languages')}>
            {filteredLanguages.map(language => (
                <FormCheck
                    key={language}
                    onChange={checked => setLanguages(checked ? [...languages, language] : pull(languages, language))}
                    checked={languages.includes(language)}
                >
                    {displayLanguage(language)}
                </FormCheck>
            ))}
        </Card>
    );
}