import AuditEditState from "../types/AuditEditState";

export default function selectCurrentStepValid(state: AuditEditState) {
    const auditId = state.auditId;
    const step = state.step;

    if (step?.name === 'question' && auditId) {
        const questionId = step.questionId;
        const measureIds = state.answers[auditId]?.[questionId]?.measureIds;

        return !measureIds?.some(measureId => {
            const measure = state.measures[measureId];

            return !measure?.responsible || !measure?.measure || !measure?.dueDate;
        })
    }

    return true;
}