import useQueryValue from "./useQueryValue";
import {useCallback} from "react";

export default function useQueryNumber(name: string, defaultValue: number = 0): [number, (value: number | undefined) => void] {
    const [value, setValue] = useQueryValue(name, defaultValue.toString());

    const setNumber = useCallback((value: number | undefined) => {
        setValue(value ? value.toString() : undefined);
    }, [setValue]);

    return [parseInt(value), setNumber];
}