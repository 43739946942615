import {createCachedSelector} from 're-reselect';
import AuditEditState from "../types/AuditEditState";

const selectAnswerGroup = createCachedSelector(
    (state: AuditEditState) => state.answerGroups[state.auditId ?? -1] || {},
    (state: AuditEditState, questionGroupId: number) => questionGroupId,
    (answerGroups, questionGroupId) => {
        return answerGroups[questionGroupId];
    }
)({
    keySelector: (state, questionGroupId) => questionGroupId
});

export default selectAnswerGroup;