import React from "react";
import {Link} from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";
import GroupStep from "../types/GroupStep";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import {useTranslation} from "react-i18next";
import useStepPath from "../hooks/useStepPath";
import isStepEqual from "../helpers/isStepEqual";
import selectCurrentGroupStep from "../selectors/selectCurrentGroupStep";
import selectQuestionGroupStatus from "../selectors/selectQuestionGroupStatus";

type Props = {
    step: GroupStep
}

export default function StepNavItem({step}: Props) {
    const {t} = useTranslation();
    const path = useStepPath(step);
    const isActive = useAuditEditSelector(state => isStepEqual(selectCurrentGroupStep(state), step));
    const title = useAuditEditSelector(state => {
        if (step.name === 'completion') {
            return t('Completion');
        } else {
            return state.questionGroups[step.questionGroupId]?.title;
        }
    });

    const state = useAuditEditSelector(state => {
        if (step.name !== 'question-group') return undefined;
        return selectQuestionGroupStatus(state, step.questionGroupId);
    })

    const description = useAuditEditSelector(state => {
        if (step.name === 'question-group') {
            return state.questionGroups[step.questionGroupId]?.title;
        }
    });

    return (
        <li className={"step-nav-item" + (isActive ? ' active' : '')}>
            <OverlayTrigger
                overlay={<Popover><Popover.Body>{description || title}</Popover.Body></Popover>}
            >
                <Link to={path}>
                    {state || title}
                    {isActive ? <span className="StepNavItem-title">{title}</span> : null}
                </Link>
            </OverlayTrigger>
        </li>
    );
}