import Question from "../../api/types/Question";
import AnswerValue from "../../api/types/AnswerValue";
import AnswerRow from "../../api/types/AnswerRow";
import AnswerLine from "../../api/types/AnswerLine";

export default function isAnswered(question: Question, value: AnswerValue) {
    switch (question.answerType) {
        case "column":
            if (!Array.isArray(value)) {
                return false;
            }

            const hasNotAnsweredRow = value.some(value => {
                const row = value as AnswerRow;

                if (!row.columns) {
                    return true;
                }

                return row.columns.some(col => col.value === null);
            });

            return !hasNotAnsweredRow;
        case "numericfield":
            if (question.metricMultiple) {
                if (!Array.isArray(value)) {
                    return false;
                }

                const hasNotAnsweredLine = value.some(value => {
                    const line = value as AnswerLine;

                    return !line.value;
                });

                return !hasNotAnsweredLine;
            }

            return value !== null;
        default:
            return value !== null;
    }
}