import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import Audit from "../../api/types/Audit";

const fetchAudits = createAsyncThunk(
    'fetchAudits',
    async ({projectId, locale}: { projectId: string, locale: string }) => {
        const audits = await request<Audit[]>('GET', `/api/projects/${projectId}/audits?locale=${locale}`);

        return {audits};
    }
);

export default fetchAudits;