import React from "react";
import {Redirect} from "react-router-dom";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import useStepPath from "../hooks/useStepPath";
import selectNextStep from "../selectors/selectNextStep";

export default function RedirectToNextStep() {
    const nextStep = useAuditEditSelector(selectNextStep);
    const path = useStepPath(nextStep);

    if (!path) {
        return null;
    }

    return <Redirect to={path}/>
}