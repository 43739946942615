import React, {useEffect, useState} from "react";
import useDispatch from "../../common/hooks/useDispatch";
import {AccessDeniedPage} from "../../app/components/AccessDeniedPage";
import ErrorPage from "../../app/components/ErrorPage";
import fetchChecklists from "../actions/fetchChecklists";
import Layout from "../../app/components/Layout";
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import ChecklistList from "./ChecklistList";
import {useParams} from "react-router-dom";
import AreasFilter from "./AreasFilter";
import TopicsFilter from "./TopicsFilter";
import LanguagesFilter from "./LanguagesFilter";

export default function AuditNewPage() {
    const dispatch = useDispatch();
    const status = useAuditNewSelector(state => state.status);
    const {locale = 'de'} = useParams<{ locale?: string }>();
    const [loadedLocale, setLoadedLocale] = useState(locale);

    useEffect(() => {
        if (status === 'loading') {
            return;
        }

        if (status === 'loaded' && loadedLocale === locale) {
            return;
        }

        setLoadedLocale(locale);
        dispatch(fetchChecklists(locale));
    }, [dispatch, status, locale, loadedLocale, setLoadedLocale]);

    if (status === 'forbidden') {
        return <AccessDeniedPage/>;
    } else if (status === 'error') {
        return <ErrorPage/>;
    }

    return (
        <Layout loading={status !== 'loaded'}>
            <div className="row">
                <div className="col-9">
                    <ChecklistList/>
                </div>
                <div className="col-3">
                    <TopicsFilter/>
                    <AreasFilter/>
                    <LanguagesFilter/>
                </div>
            </div>
        </Layout>
    )
}