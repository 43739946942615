import ChecklistVersionDetail from "../../api/types/ChecklistVersionDetail";
import merge from "ts-deepmerge";
import normalizeQuestionGroup from "./normalizeQuestionGroup";
import undefinity from "../../common/helpers/undefinity";

export default function normalizeChecklistVersionDetail(checklistVersion: ChecklistVersionDetail) {
    const {questionGroups, ...rest} = checklistVersion;

    return merge({
        checklistVersions: {
            [checklistVersion.id]: undefinity({
                ...rest,
                questionGroupIds: questionGroups.map(questionGroup => questionGroup.id)
            })
        }
    }, ...questionGroups.map(questionGroup => normalizeQuestionGroup(questionGroup, checklistVersion.id)));
}