import React from "react";
import "./StepNav.css";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import StepNavItem from "./StepNavItem";
import getStepPath from "../helpers/getStepPath";
import selectGroupSteps from "../selectors/selectGroupSteps";
import selectCurrentGroupStep from "../selectors/selectCurrentGroupStep";
import useQueryFlag from "../../router/hooks/useQueryFlag";

export default function StepNav() {
    const [collapsed] = useQueryFlag('collapsed');

    const steps = useAuditEditSelector(state => {
        const steps = selectGroupSteps(state);

        if (!collapsed || steps.length < 10) {
            return steps;
        }

        const currentStep = selectCurrentGroupStep(state);
        const currentStepPath = currentStep ? getStepPath(currentStep) : undefined;

        return steps.filter(step => {
            if (getStepPath(step) === currentStepPath) {
                return true;
            }

            if (step.name !== 'question-group') {
                return true;
            }

            return step.parent;
        })
    });


    return (
        <nav aria-label="step">
            <ol className="step-nav">
                {steps.map(step => <StepNavItem step={step} key={getStepPath(step)}/>)}
            </ol>
        </nav>
    )
}