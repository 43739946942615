import AuditNewState from "../types/AuditNewState";

export default function createAuditNewState(): AuditNewState {
    return {
        checklists: {},
        checklistIds: [],
        areas: {},
        areaIds: [],
        topics: {},
        topicIds: [],
    };
}