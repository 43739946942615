import Filter from "../types/Filter";
import createCachedSelector from "re-reselect";
import selectMeasureIdsFiltered from "./selectChecklistIdsFiltered";
import MeasureListState from "../types/MeasureListState";

const selectMeasureIdsFilteredPaginated = createCachedSelector(
    (state: MeasureListState, filter: Filter) => selectMeasureIdsFiltered(state, filter),
    (state: MeasureListState, filter: Filter, perPage: number) => perPage,
    (measureIds, perPage) => {
        let pageIndex = 0;
        let itemsCount = 0;
        let measureIdsPaginated: string[][] = [[]];

        measureIds?.forEach(measureId => {
            if (itemsCount >= perPage) {
                pageIndex++;
                itemsCount = 0;
                measureIdsPaginated.push([]);
            }

            measureIdsPaginated[pageIndex].push(measureId);
            itemsCount += 1;
        })

        return measureIdsPaginated;
    }
)(
    {
        keySelector: (state, filter, perPage) => {
            return JSON.stringify({filter, perPage});
        }
    }
);

export default selectMeasureIdsFilteredPaginated;