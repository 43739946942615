import React, {useEffect} from "react";
import Layout from "../../app/components/Layout";
import {useParams} from "react-router-dom";
import useDispatch from "../../common/hooks/useDispatch";
import useMeasureListSelector from "../hooks/useMeasureListSelector";
import MeasureList from "./MeasureList";
import {measureListActions} from "../measureListSlice";
import ResponsibleFilter from "./ResponsibleFilter";
import ShowDoneFilter from "./ShowDoneFilter";
import fetchMeasures from "../actions/fetchMeasures";
import AuditFilter from "./AuditFilter";
import {AccessDeniedPage} from "../../app/components/AccessDeniedPage";
import ErrorPage from "../../app/components/ErrorPage";

export default function MeasureListPage() {
    const {projectId, locale} = useParams<{ projectId: string, locale: string }>();
    const status = useMeasureListSelector(state => state.status);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            dispatch(measureListActions.resetStatus());
            dispatch(fetchMeasures({projectId, locale}));
        })();
    }, [locale, projectId, dispatch]);

    if (status === 'forbidden') {
        return <AccessDeniedPage/>;
    } else if (status === 'error') {
        return <ErrorPage/>;
    }

    return (
        <Layout loading={status !== 'loaded'}>
            <div className="float-end d-flex align-items-center">
                <ShowDoneFilter/>
                <ResponsibleFilter/>
                <AuditFilter/>
            </div>
            <br className="clearfix"/>
            <br className="clearfix"/>
            <MeasureList/>
        </Layout>
    );
}