import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {auditEditActions} from "../auditEditSlice";
import {Modal} from "react-bootstrap";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import createMeasure from "../factories/createMeasure";
import Measure from "../../api/types/Measure";

type Props = {
    auditId: string,
    questionId: number
}

export default function MeasureAddButton({auditId, questionId}: Props) {
    const predefinedMeasures = useAuditEditSelector(state => state.questions[questionId]?.predefinedMeasures || []);
    const dispatch = useDispatch();
    const [modalOpen, setOpenModal] = useState(false);
    const [selected, setSelected] = useState<number[]>([]);
    const {t} = useTranslation();

    const create = () => {
        if (predefinedMeasures.length) {
            setOpenModal(true);
        } else {
            dispatch(auditEditActions.addMeasures({auditId, questionId, measures: [createMeasure()]}))
        }
    }

    const close = () => {
        setOpenModal(false);
        setSelected([]);
    }

    const add = () => {
        const measures: Measure[] = selected.length ?
            predefinedMeasures.filter((measure, index) => (selected.indexOf(index) !== -1))
                .map(createMeasure)
            : [createMeasure()];

        dispatch(auditEditActions.addMeasures({auditId, questionId, measures}))
        close();
    }

    return (
        <>
            <Modal show={modalOpen} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Add measure')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {predefinedMeasures.map((predefinedMeasure, index) => {
                        const id = "predefined-measure-" + index;

                        return (
                            <div className="form-check mb-2" key={index}>
                                <input type="checkbox" className="form-check-input" id={id}
                                       checked={selected.indexOf(index) !== -1}
                                       onChange={e => e.target.checked ?
                                           setSelected([...selected, index]) :
                                           setSelected(selected.filter(i => i !== index))
                                       }/>
                                <label className="form-check-label" htmlFor={id}>{predefinedMeasure.measure}</label>
                            </div>
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" onClick={add}>{t('Add measure')}</button>
                </Modal.Footer>
            </Modal>
            <button onClick={create} className="btn btn-link p-0">{t('Add measure')}</button>
        </>
    );
}