import {createSlice} from "@reduxjs/toolkit";
import createAuditListState from "./factories/createAuditListState";
import fetchAudits from "./actions/fetchAudits";
import deleteAudit from "./actions/deleteAudit";
import draftArrayRemove from "../common/helpers/draftArrayRemove";

const auditListSlice = createSlice({
    name: 'auditList',
    initialState: createAuditListState(),
    reducers: {
        resetStatus: state => {
            delete state.status;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteAudit.fulfilled, (state, {meta}) => {
            const auditId = meta.arg.auditId;
            draftArrayRemove(state.auditIds ?? [], auditId);
        })
        builder.addCase(fetchAudits.pending, state => {
            state.status = 'loading';
        })
        builder
            .addCase(fetchAudits.rejected, (state, {error}) => {
                switch (error.code) {
                    case '401':
                    case '403':
                        state.status = 'forbidden';
                        break;
                    case '404':
                        state.status = 'notfound';
                        break;
                    default:
                        state.status = 'error';
                }
            })
        builder
            .addCase(fetchAudits.fulfilled, (state, {payload}) => {
                const {audits} = payload;
                state.status = 'loaded';

                let auditIds: string[] = [];

                audits.forEach(audit => {
                    auditIds.push(audit.id);
                    state.audits[audit.id] = audit;
                });

                state.auditIds = auditIds;
            });
    },
});

export const auditListReducer = auditListSlice.reducer;
export const auditListActions = auditListSlice.actions;

