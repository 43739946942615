import createCachedSelector from "re-reselect";
import AuditNewState from "../types/AuditNewState";
import Filter from "../types/Filter";
import selectChecklistIdsFiltered from "./selectChecklistIdsFiltered";

const selectLanguagesFiltered = createCachedSelector(
    (state: AuditNewState, filter: Filter) => selectChecklistIdsFiltered(state, filter),
    (state: AuditNewState) => state.checklists,
    (checklistIds, checklists) => {
        let languages: string[] = [];
        checklistIds.forEach(checklistId => {
            checklists[checklistId]?.languages.forEach(language => {
                if (!languages.includes(language)) {
                    languages.push(language);
                }
            })
        });

        return languages;
    }
)({
    keySelector: (state, filter) => JSON.stringify(filter)
});

export default selectLanguagesFiltered;