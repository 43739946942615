import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";

const toggleDone = createAsyncThunk(
    'toggleDone',
    async ({projectId, measureId, done}: { projectId: string, measureId: string, done: boolean }) => {
        if (done) {
            await request('POST', `/api/projects/${projectId}/measures/${measureId}/done`);
        } else {
            await request('POST', `/api/projects/${projectId}/measures/${measureId}/open`);
        }
    }
);

export default toggleDone;