import {createSlice} from "@reduxjs/toolkit";
import fetchChecklists from "./actions/fetchChecklists";
import createAuditNewState from "./factories/createAuditListState";


const auditNewSlice = createSlice({
    name: 'auditNew',
    initialState: createAuditNewState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchChecklists.pending, state => {
            state.status = 'loading';
        })
        builder
            .addCase(fetchChecklists.rejected, (state, {error}) => {
                switch (error.code) {
                    case '401':
                    case '403':
                        state.status = 'forbidden';
                        break;
                    default:
                        state.status = 'error';
                }
            })
        builder
            .addCase(fetchChecklists.fulfilled, (state, {payload}) => {
                const {checklists, areas, topics} = payload;
                let checklistIds: number[] = [];
                checklists.forEach(checklist => {
                    checklistIds.push(checklist.id);
                    state.checklists[checklist.id] = checklist;
                });
                state.checklistIds = checklistIds;
                let areaIds: number[] = [];
                areas.forEach(area => {
                    areaIds.push(area.id);
                    state.areas[area.id] = area;
                });
                state.areaIds = areaIds;
                let topicIds: number[] = [];
                topics.forEach(topic => {
                    topicIds.push(topic.id);
                    state.topics[topic.id] = topic;
                });
                state.topicIds = topicIds;
                state.status = 'loaded';
            });
    },
});

export const auditNewReducer = auditNewSlice.reducer;
export const auditNewActions = auditNewSlice.actions;

