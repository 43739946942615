import React, {useEffect, useMemo} from "react";
import Pagination from "../../common/components/Paginaton";
import {useTranslation} from "react-i18next";
import MeasureListRow from "./MeasureListRow";
import useMeasureListSelector from "../hooks/useMeasureListSelector";
import selectMeasureIdsFilteredPaginated from "../selectors/selectMeasureIdsFilteredPaginated";
import useQueryNumber from "../../router/hooks/useQueryNumber";
import useQueryFlag from "../../router/hooks/useQueryFlag";
import useQueryValue from "../../router/hooks/useQueryValue";

export const MEASURE_TABLE_COLS = 8;

export default function MeasureList() {
    const {t} = useTranslation();
    const [page, setPage] = useQueryNumber('page', 1);
    const [showDone] = useQueryFlag('show-done');
    const [responsible] = useQueryValue('responsible');
    const [auditId] = useQueryValue('audit');

    const measureIdsPaginated = useMeasureListSelector(
        state => selectMeasureIdsFilteredPaginated(state, {showDone, responsible, auditId}, 15)
    );
    const measureIds = useMemo(() => measureIdsPaginated[page - 1] ?? [], [page, measureIdsPaginated]);
    const loaded = useMeasureListSelector(state => state.status === 'loaded');

    useEffect(() => {
        if (loaded && page !== 1 && !measureIds.length) {
            setPage(1);
        }
    }, [loaded, measureIds, page, setPage]);

    if (page !== 1 && !measureIds.length) {
        return null;
    }

    return (
        <div className="d-flex flex-column">
            <div className="flex-grow-1">
                <table className="table table-striped table-sm">
                    <thead>
                    <tr className="bg-secondary bg-opacity-25">
                        <th style={{width: '30%'}}>{t('Measure')}</th>
                        <th style={{width: '30%'}}>{t('Checklist')}</th>
                        <th style={{width: '30%'}}>{t('Checklist Position')}</th>
                        <th>{t('Due date')}</th>
                        <th>{t('Responsible')}</th>
                        {showDone && <th>{t('State')}</th>}
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {measureIds.length === 0 && loaded && (
                        <tr>
                            <td colSpan={MEASURE_TABLE_COLS - (showDone ? 0 : 1)}>
                                <p className="text-center m-3">{t('No measures')}</p>
                            </td>
                        </tr>
                    )}
                    {measureIds.map(measureId => <MeasureListRow key={measureId} measureId={measureId}/>)}
                    </tbody>
                </table>
            </div>
            <Pagination pages={measureIdsPaginated.length} {...{page, setPage}} />
        </div>
    );
}