import normalizeAnswerGroup from "../normalizer/normalizeAnswerGroup";
import AnswerGroup from "../../api/types/AnswerGroup";
import normalizeQuestionGroup from "../normalizer/normalizeQuestionGroup";
import denormalizeAnswer from "./denormalizeAnswer";

type Normalized = ReturnType<typeof normalizeAnswerGroup> & ReturnType<typeof normalizeQuestionGroup>;

export default function denormalizeAnswerGroup(normalized: Normalized, auditId: string, questionGroupId: number): AnswerGroup | undefined {
    const answerGroup = normalized.answerGroups[auditId]?.[questionGroupId];
    const questionGroup = normalized.questionGroups[questionGroupId];

    if (!answerGroup || !questionGroup) {
        console.error(
            'Answer group could not be denormalized. Answer group or question group not found:',
            {auditId, questionGroupId, normalized}
        );

        return undefined;
    }

    return {
        ...answerGroup,
        answers: questionGroup.questionIds.flatMap(questionId => {
            const answer = denormalizeAnswer(normalized, auditId, questionId);
            return answer ? [answer] : [];
        }),
    }
}