import QuestionGroup from "../../api/types/QuestionGroup";
import merge from "ts-deepmerge";
import undefinity from "../../common/helpers/undefinity";
import normalizeQuestion from "./normalizeQuestion";

export default function normalizeQuestionGroup(questionGroup: QuestionGroup, checklistVersionId: number) {
    const {questions, ...rest} = questionGroup;

    return merge({
        questionGroups: {
            [questionGroup.id]: undefinity({
                ...rest,
                checklistVersionId,
                questionIds: questions.map(question => question.id)
            })
        },
    }, ...questions.map(question => normalizeQuestion(question, questionGroup.id)))
}