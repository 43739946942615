import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import createAuditEditState from "./factories/createAuditEditState";
import Step from "./types/Step";
import draftArrayRemove from "../common/helpers/draftArrayRemove";
import Photo from "../api/types/Photo";
import AuditEditState from "./types/AuditEditState";
import Measure from "../api/types/Measure";
import Comment from "../api/types/Comment";
import AnswerValue from "../api/types/AnswerValue";
import mergeDraftState from "../common/helpers/mergeDraftState";
import normalizeAuditDetail from "./normalizer/normalizeAuditDetail";
import normalizeChecklistVersionDetail from "./normalizer/normalizeChecklistVersionDetail";
import AnswerGroup from "../api/types/AnswerGroup";
import normalizeAnswerGroup from "./normalizer/normalizeAnswerGroup";
import fetchAudit from "./actions/fetchAudit";
import updateAudit from "./actions/updateAudit";
import finishAudit from "./actions/finishAudit";

const auditEditSlice = createSlice({
    name: 'auditEdit',
    initialState: createAuditEditState(),
    reducers: {
        updateByParams: (state, {payload}: PayloadAction<{ step?: Step, auditId?: string }>) => {
            state.step = payload.step;
            state.auditId = payload.auditId;
        },
        setAnswerGroupValue: (state, {payload}: PayloadAction<{ auditId: string, questionGroupId: number, value: boolean }>) => {
            const {auditId, questionGroupId, value} = payload;
            const answerGroup = state.answerGroups[auditId]?.[questionGroupId];

            if (answerGroup) {
                answerGroup.value = value;
            }
        },
        setAnswerGroup: (state, {payload}: PayloadAction<{ auditId: string, answerGroup: AnswerGroup }>) => {
            const {auditId, answerGroup} = payload;
            mergeDraftState(state, normalizeAnswerGroup(answerGroup, auditId));
        },
        useAnswersFromCopy: (state, {payload}: PayloadAction<{ auditId: string, questionGroupId: number }>) => {
            const {auditId, questionGroupId} = payload;
            const copyFromId = state.audits[auditId]?.copyFromId;
            const copyFromAnswerGroup = state.answerGroups[copyFromId ?? -1]?.[questionGroupId];
            const answerGroup = state.answerGroups[auditId]?.[questionGroupId];

            if (answerGroup && copyFromAnswerGroup) {
                answerGroup.value = copyFromAnswerGroup.value;
            }


        },
        removePhoto: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, photoId: string }>) => {
            const {auditId, questionId, photoId} = payload;

            delete state.photos[photoId];
            draftArrayRemove(state.answers[auditId]?.[questionId]?.photoIds || [], photoId);
        },
        addPhoto: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, photo: Photo }>) => {
            const {auditId, questionId, photo} = payload;

            state.answers[auditId]?.[questionId]?.photoIds.push(photo.id);
            state.photos[photo.id] = photo;
        },
        updateMeasure: (state, {payload}: PayloadAction<{ measureId: string, values: Partial<NonNullable<AuditEditState['measures'][string]>> }>) => {
            const {measureId, values} = payload;
            const measure = state.measures[measureId];
            if (measure) {
                state.measures[measureId] = {...measure, ...values};
            }
        },
        removeMeasure: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, measureId: string }>) => {
            const {auditId, questionId, measureId} = payload;

            delete state.measures[measureId];
            draftArrayRemove(state.answers[auditId]?.[questionId]?.measureIds || [], measureId);
        },
        addMeasures: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, measures: Measure[] }>) => {
            const {auditId, questionId, measures} = payload;

            measures.forEach(measure => {
                state.answers[auditId]?.[questionId]?.measureIds.push(measure.id);
                state.measures[measure.id] = measure;
            });
        },
        updateComment: (state, {payload}: PayloadAction<{ commentId: string, values: Partial<NonNullable<AuditEditState['comments'][string]>> }>) => {
            const {commentId, values} = payload;
            const comment = state.comments[commentId];
            if (comment) {
                state.comments[commentId] = {...comment, ...values};
            }
        },
        removeComment: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, commentId: string }>) => {
            const {auditId, questionId, commentId} = payload;

            delete state.comments[commentId];
            draftArrayRemove(state.answers[auditId]?.[questionId]?.commentIds || [], commentId);
        },
        addComments: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, comments: Comment[] }>) => {
            const {auditId, questionId, comments} = payload;

            comments.forEach(comment => {
                state.answers[auditId]?.[questionId]?.commentIds.push(comment.id);
                state.comments[comment.id] = comment;
            });
        },
        setAnswerValue: (state, {payload}: PayloadAction<{ auditId: string, questionId: number, value: AnswerValue }>) => {
            const {auditId, questionId, value} = payload;
            const answer = state.answers[auditId]?.[questionId];

            if (answer) {
                answer.value = value;
            }
        },
        updateAudit: (state, {payload}: PayloadAction<{ auditId: string, values: Partial<NonNullable<AuditEditState['audits'][number]>> }>) => {
            const {auditId, values} = payload;
            const audit = state.audits[auditId];
            if (audit) {
                state.audits[auditId] = {...audit, ...values};
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAudit.pending, (state, {meta}) => {
                state.status[meta.arg.auditId] = 'pending';
                state.updateFailed = false;
            })
        builder
            .addCase(fetchAudit.rejected, (state, {meta, error}) => {
                switch (error.code) {
                    case '401':
                    case '403':
                        state.status[meta.arg.auditId] = 'forbidden';
                        break;
                    case '404':
                        state.status[meta.arg.auditId] = 'notfound';
                        break;
                    default:
                        state.status[meta.arg.auditId] = 'error';
                }
            })
        builder
            .addCase(fetchAudit.fulfilled, (state, {payload}) => {
                const {audit, checklistVersion, auditCopyFrom} = payload;
                state.status[audit.id] = 'loaded';
                mergeDraftState(state, normalizeAuditDetail(audit));

                if (auditCopyFrom) {
                    mergeDraftState(state, normalizeAuditDetail(auditCopyFrom));
                }
                mergeDraftState(state, normalizeChecklistVersionDetail(checklistVersion));
            });
        builder
            .addCase(updateAudit.rejected, (state, {error}) => {
                state.updateFailed = error.code === '401' || error.code === '403' ? 'forbidden' : 'error';
            })
            .addCase(updateAudit.pending, (state) => {
                state.updateFailed = false;
            })
            .addCase(finishAudit.rejected, (state, {error}) => {
                state.updateFailed = error.code === '401' || error.code === '403' ? 'forbidden' : 'error';
            })
    },
});

export const auditEditReducer = auditEditSlice.reducer;
export const auditEditActions = auditEditSlice.actions;

