import React, {useEffect} from "react";
import Layout from "../../app/components/Layout";
import AuditList from "./AuditList";
import {Link, useParams} from "react-router-dom";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import useBaseUrl from "../../app/hooks/useBaseUrl";
import useDispatch from "../../common/hooks/useDispatch";
import useAuditListSelector from "../hooks/useAuditEditSelector";
import fetchAudits from "../actions/fetchAudits";
import {auditListActions} from "../auditListSlice";
import {AccessDeniedPage} from "../../app/components/AccessDeniedPage";
import ErrorPage from "../../app/components/ErrorPage";
import NotFoundPage from "../../app/components/NotFoundPage";

export default function AuditListPage() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {projectId, locale = 'de'} = useParams<{ projectId: string, locale?: string }>();
    const baseUrl = useBaseUrl();
    const status = useAuditListSelector(state => state.status);

    useEffect(() => {
        (async () => {
            dispatch(auditListActions.resetStatus())
            dispatch(fetchAudits({projectId, locale}));
        })();
    }, [dispatch, projectId, locale]);

    if (status === 'forbidden') {
        return <AccessDeniedPage/>;
    } else if (status === 'notfound') {
        return <NotFoundPage/>;
    } else if (status === 'error') {
        return <ErrorPage/>;
    }

    return (
        <Layout loading={status !== 'loaded'}>
            <div className="clearfix mb-2">
                <Link className="float-end" to={`${baseUrl}/new`}>
                    <FontAwesomeIcon icon={faPlusCircle}/> {t('Start new checklist')}
                </Link>
            </div>
            <AuditList/>
        </Layout>
    );
}