import React from "react";
import {useTranslation} from "react-i18next";
import UserSelect from "../../users/components/UserSelect";
import useQueryValue from "../../router/hooks/useQueryValue";

export default function ResponsibleFilter() {
    const [responsible, setResponsible] = useQueryValue('responsible');
    const {t} = useTranslation();

    return (
        <div style={{width: 200}} className="me-3">
            <UserSelect className="form-select-sm" onChange={setResponsible} value={responsible}
                        placeholder={t('Responsible')}/>
        </div>
    );
}