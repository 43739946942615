import {useHistory, useLocation} from "react-router-dom";
import {useCallback} from "react";

export default function useQueryValue(name: string, defaultValue: string = ''): [string, (value: string | undefined) => void] {
    const history = useHistory();
    const location = useLocation();

    const setValue = useCallback((value: string | undefined) => {
        const params = new URLSearchParams(location.search);

        if (!value) {
            params.delete(name)
        } else {
            params.set(name, value)
        }

        location.search = params.toString();
        history.replace(location);
    }, [name, location, history]);

    return [new URLSearchParams(location.search).get(name) || defaultValue, setValue];
}