import React from "react";
import {Redirect} from "react-router-dom";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import selectSteps from "../selectors/selectSteps";
import selectAnswerGroup from "../selectors/selectAnswerGroup";
import Step from "../types/Step";
import selectAnswer from "../selectors/selectAnswer";
import useStepPath from "../hooks/useStepPath";
import isAnswered from "../helpers/isAnswered";

export default function RedirectToLastQuestion() {
    const lastStep = useAuditEditSelector(state => {
        const steps = selectSteps(state);
        let lastStep: Step | undefined = undefined;

        steps.some((step, i) => {
            lastStep = step;
            switch (step.name) {
                case 'question-group':
                    const questionGroup = state.questionGroups[step.questionGroupId];
                    const answerGroup = selectAnswerGroup(state, step.questionGroupId);
                    const nextStep = steps[i + 1];

                    if (questionGroup?.question) {
                        if (answerGroup?.value === null) {
                            return true;
                        }
                    } else if (nextStep?.name === 'question') {
                        const answer = selectAnswer(state, nextStep.questionId);
                        const question = state.questions[nextStep.questionId];

                        if (question && answer && !isAnswered(question, answer.value)) {
                            return true;
                        }
                    }
                    break;
                case 'question':
                    const answer = selectAnswer(state, step.questionId);
                    const question = state.questions[step.questionId];

                    if (question && answer && !isAnswered(question, answer.value)) {
                        return true;
                    }
                    break;
            }

            return false;
        })

        return lastStep;
    });

    const path = useStepPath(lastStep);

    if (!path) {
        return null;
    }

    return <Redirect to={path}/>
}