import AuditListState from "../types/AuditListState";
import selectGroupedByChecklistData from "./selectGroupedByChecklistData";
import createCachedSelector from "re-reselect";

const selectAuditsByChecklistId = createCachedSelector(
    selectGroupedByChecklistData,
    state => state.audits,
    (state: AuditListState, checklistId: number) => checklistId,
    ({groupedByChecklist}, audits, checklistId) => {
        return (groupedByChecklist[checklistId] ?? []).flatMap(auditId => {
            const audit = audits[auditId];
            return audit ? [audit] : [];
        });
    }
)({
    keySelector: (state, checklistId) => checklistId
});

export default selectAuditsByChecklistId;