import {store} from "../../../store";
import denormalizeAuditDetail from "../denormalizer/denormalizeAuditDetail";
import debounce from "debounce";
import updateAudit from "../actions/updateAudit";

export default function syncAudit(projectId: string, auditId: string) {
    const {getState} = store;
    let savedState = getState().auditEdit;
    let savedBody = JSON.stringify(denormalizeAuditDetail(savedState, auditId));
    let running = false;

    const check = debounce(async () => {
        if (running) {
            check();
            return;
        }

        const nextState = getState().auditEdit;
        const audit = denormalizeAuditDetail(nextState, auditId);
        const nextBody = JSON.stringify(audit);

        if (savedBody === nextBody || !audit) {
            return;
        }

        running = true;
        await store.dispatch(updateAudit({projectId, audit}));

        running = false;
        savedBody = nextBody;
        savedState = nextState;
    }, 500);

    return store.subscribe(() => {
        const nextState = getState().auditEdit;
        if (savedState !== nextState) {
            check();
        }
    });
}