import React, {useEffect, useState} from "react";
import styles from "./NumberInput.module.css";
import {useTranslation} from "react-i18next";

type Props = {
    maxWidth?: number
    value?: number,
    id: string,
    onChange: (value?: number) => void,
    unit?: string,
    min?: number,
    max?: number
}

export default function NumberInput({onChange, value, id, unit, min, max, maxWidth}: Props) {
    const [inputValue, setInputValue] = useState(value);
    const [validated, setValidated] = useState(typeof value !== "undefined");
    const {t} = useTranslation();

    useEffect(() => {
        setInputValue(value);
    }, [value])

    const onBlur = () => {
        setValidated(true)
        onChange(inputValue);
    };

    const getInvalidMessage = () => {
        if (min && max) {
            return <span>{t('This value should be between {{min}} and {{max}}.', {min, max})}</span>
        } else if (min) {
            return <span>{t('This value shouldn\'t be less than {{min}}.', {min})}</span>
        } else if (max) {
            return <span>{t('This value should not be greater than {{max}}.', {max})}</span>
        } else {
            return <span>{t('This value is invalid.')}</span>
        }
    }

    return (
        <>
            <div className={"input-group" + (validated ? " was-validated" : "")} style={{maxWidth: maxWidth}}>
                <input type="number" className={"form-control " + styles.input}
                       id={id}
                       onBlur={onBlur}
                       value={inputValue}
                       required
                       min={min}
                       max={max}
                       onChange={e => setInputValue(e.target.value ? Number(e.target.value) : undefined)}
                />
                {
                    unit && <div className="input-group-append">
                        <span className="input-group-text" dangerouslySetInnerHTML={{__html: unit}}/>
                    </div>
                }
                <div className="invalid-feedback">{getInvalidMessage()}</div>
            </div>
        </>
    );
}