import React from 'react';
import useAuditNewSelector from "../hooks/useAuditNewSelector";
import useDisplayLanguage from "../../common/hooks/useDisplayLanguage";
import ChecklistStartButton from "./ChecklistStartButton";

type Props = {
    checklistId: number
};

function ChecklistListRow({checklistId}: Props) {
    const checklist = useAuditNewSelector(state => state.checklists[checklistId]);
    const topicNames = useAuditNewSelector(state =>
        state.checklists[checklistId]?.topicIds.map(id => state.topics[id]?.name)
    );
    const displayLanguage = useDisplayLanguage();

    if (!checklist) {
        return null;
    }

    return (
        <tr>
            <td>{checklist.name}</td>
            <td>{topicNames?.join(', ')}</td>
            <td>{checklist.languages.map(displayLanguage).join(', ')}</td>
            <td className="text-right"><ChecklistStartButton checklistId={checklistId}/></td>
        </tr>
    );
}

export default React.memo(ChecklistListRow);