import React from "react";
import moment from "moment";
import useMeasureListSelector from "../hooks/useMeasureListSelector";
import selectAuditData from "../selectors/selectAuditData";

type Props = {
    auditId: string
}

export default function AuditFilterOption({auditId}: Props) {
    const audit = useMeasureListSelector(state => selectAuditData(state).auditData[auditId]);

    if (!audit) {
        return null;
    }

    const date = moment(audit.completedAt).format('D. MMM  YYYY');

    return (
        <option value={audit.id}>
            {audit.checklistVersion?.name} - {audit.reason ? date + ' - ' + audit.reason : date}
        </option>
    );
}