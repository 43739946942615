import AuditDetail from "../../api/types/AuditDetail";

let uploaded: Map<string, boolean> = new Map();

export default async function uploadPhoto(audit: AuditDetail) {
    for (const answerGroup of audit.answerGroups) {
        for (const answer of answerGroup.answers) {
            for (const photo of answer.photos) {
                if (photo.url && photo.url.startsWith('blob:') && !uploaded.has(photo.id)) {
                    const response = await fetch(photo.url);
                    const blob = await response.blob();
                    const formData = new FormData();

                    formData.append('id', photo.id)
                    formData.append('file', blob);

                    const url = new URL((process.env.REACT_APP_API_URL || '') + '/api/photos', window.location.href);
                    await fetch(url.toString(), {
                        method: "POST", body: formData, credentials: "include"
                    });

                    uploaded.set(photo.id, true);
                }
            }
        }
    }

    return audit;
}
