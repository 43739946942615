import React from "react";

type Props = {
    id?: string
    value?: string
    onChange: (value?: string) => void
}

export default function IntervalInputGroup({value, onChange, id}: Props) {
    const match = /^(?<number>[0-9]*)(?<interval>[DWMY]?)$/.exec(value || '');

    const number = match?.groups?.number || '';
    const interval = match?.groups?.interval || '';

    return (
        <div className="input-group">
            <input type="number" className="form-control"
                   style={{maxWidth: 60}}
                   id={id}
                   value={number}
                   min={1}
                   max={99}
                   onChange={e => {
                       const input = Math.max(Math.min(Number(e.target.value), 99), 1);
                       onChange(input + '' + interval)
                   }}
            />

            <select className="input-group-append form-select"
                    value={interval}
                    onChange={e => onChange(number + '' + e.target.value)}>
                <option/>
                <option value="D">Tage</option>
                <option value="W">Wochen</option>
                <option value="M">Monate</option>
                <option value="Y">Jahre</option>
            </select>
        </div>
    );
}