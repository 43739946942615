import React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import selectNextGroupStep from "../selectors/selectNextGroupStep";
import {useHistory} from "react-router-dom";
import useStepPath from "../hooks/useStepPath";
import useDispatch from "../../common/hooks/useDispatch";
import setLastAnswers from "../actions/setLastAnswers";

type Props = {
    auditId: string
    questionGroupId: number
}

export default function AnswerGroupLastAnswers({auditId, questionGroupId}: Props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const copyFromId = useAuditEditSelector(state => state.audits[auditId]?.copyFromId);
    const createdAt = useAuditEditSelector(state => state.audits[copyFromId ?? -1]?.createdAt);
    const allowToUseAnswersFromCopy = useAuditEditSelector(state => state.questionGroups[questionGroupId]?.allowToUseAnswersFromCopy)
    const nextGroupStep = useAuditEditSelector(selectNextGroupStep);
    const history = useHistory();
    const path = useStepPath(nextGroupStep);

    const onClick = () => {
        dispatch(setLastAnswers(auditId, questionGroupId));
        history.push(path);
    }

    if (!createdAt || !allowToUseAnswersFromCopy) {
        return null;
    }

    return (
        <div className="alert alert-warning">
            <button className="btn btn-link alert-link p-0" onClick={onClick}>
                {t('Nothing has changed in this area. Use the answers from the checklist from {{date}}.', {
                    date: moment(createdAt).format('D. MMMM YY')
                })}
            </button>
        </div>
    );
}

