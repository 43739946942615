import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../api/helpers/request";
import Notification from "../../api/types/Notification";

const fetchNotifications = createAsyncThunk(
    'fetchNotifications',
    async (locale: string) => {
        return await request<Notification[]>('GET', `/api/users/me/notifications?locale=${locale}`);
    }
);

export default fetchNotifications;
