import React, {FormEvent} from "react";
import QuestionColumn from "../../api/types/QuestionColumn";

type Props = {
    id: string
    value: string | null,
    onChange: (value: string | null) => void,
    questionColumn: QuestionColumn,
}

export default function AnswerColumnValueForm({id, value, questionColumn, onChange}: Props) {
    const inputValue = value ? value : '';
    const onInputChange = (e: FormEvent<HTMLInputElement>) => {
        return onChange(e.currentTarget.value);
    };

    switch (questionColumn.dataType) {
        case "text":
            return <input id={id} className="form-control" type="text" value={inputValue} onChange={onInputChange}/>;
        case "numeric":
            return (
                <div className="input-group">
                    <input type="text" className="form-control" id={id} value={inputValue} onChange={onInputChange}/>
                    {
                        questionColumn.unit && <div className="input-group-append">
                            <span className="input-group-text" dangerouslySetInnerHTML={{__html: questionColumn.unit}}/>
                        </div>
                    }
                </div>
            )
        case "datetime":
            return <input id={id} className="form-control" type="datetime-local" value={inputValue}
                          onChange={onInputChange}/>;
        case "date":
            return <input id={id} className="form-control" type="date" value={inputValue} onChange={onInputChange}/>;
    }

    return null;
}