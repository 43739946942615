import Question from "../../api/types/Question";
import undefinity from "../../common/helpers/undefinity";

export default function normalizeQuestion(question: Question, questionGroupId: number) {
    return {
        questions: {
            [question.id]: undefinity({
                ...question,
                questionGroupId
            })
        }
    }
}