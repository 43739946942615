import getStepPath from "./getStepPath";
import Step from "../types/Step";

export default function findStep<T extends Step>(steps: T[], currentStep: T, increment: number): T | undefined {
    const currentPath = getStepPath(currentStep);
    const index = steps.findIndex(groupStep => getStepPath(groupStep) === currentPath);

    if (index >= -1 && steps[index + increment]) {
        return steps[index + increment];
    } else {
        return undefined;
    }
}