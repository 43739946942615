import AuditEditState from "../types/AuditEditState";
import selectQuestionSteps from "./selectQuestionSteps";
import selectAnsweredQuestionSteps from "./selectAnsweredQuestionSteps";
import selectAnswerGroup from "./selectAnswerGroup";
import {createCachedSelector} from "re-reselect";

const selectQuestionGroupStatus = createCachedSelector(
    (state: AuditEditState) => state.step,
    (state: AuditEditState, questionGroupId: number) => selectAnsweredQuestionSteps(state, questionGroupId).length,
    (state: AuditEditState, questionGroupId: number) => selectQuestionSteps(state, questionGroupId).length,
    (state: AuditEditState, questionGroupId: number) => state.questionGroups[questionGroupId],
    (state: AuditEditState, questionGroupId: number) => selectAnswerGroup(state, questionGroupId),
    (step, answered, questions, questionGroup, answerGroup): string | undefined => {
        if (questionGroup?.question && answerGroup?.value === null) {
            return '?';
        } else if (questionGroup?.question && !answerGroup?.value) {
            return '-';
        } else if (questionGroup?.parentTitle) {
            return undefined
        }

        return `${answered} / ${questions}`;
    }
)({
    keySelector: (state, questionGroupId) => questionGroupId
});

export default selectQuestionGroupStatus;