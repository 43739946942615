import React from "react";
import {pull} from "lodash";
import {useTranslation} from "react-i18next";
import AnswerLine from "../../api/types/AnswerLine";
import createAnswerLine from "../factories/createAnswerLine";
import useAuditEditSelector from "../hooks/useAuditEditSelector";
import AnswerColumn from "../../api/types/AnswerColumn";
import AnswerRow from "../../api/types/AnswerRow";
import AnswerValue from "../../api/types/AnswerValue";
import AnswerLineForm from "./AnswerLineForm";
import NumberInput from "../../common/components/NumberInput";
import createAnswerRow from "../factories/createAnswerRow";
import AnswerRowForm from "./AnswerRowForm";
import arrayReplace from "../../common/helpers/arrayReplace";
import arraySplice from "../../common/helpers/arraySplice";
import {auditEditActions} from "../auditEditSlice";
import {useDispatch} from "react-redux";

type Props = {
    auditId: string,
    questionId: number
}

function AnswerValueForm({auditId, questionId}: Props) {
    const dispatch = useDispatch();
    const question = useAuditEditSelector(state => state.questions[questionId]);
    const answerValue = useAuditEditSelector(state => state.answers[auditId]?.[questionId]?.value);
    const {t} = useTranslation();

    if (!question) {
        return null;
    }

    const onChange = (value: AnswerValue) => {
        dispatch(auditEditActions.setAnswerValue({auditId, questionId, value}));
    }

    switch (question.answerType) {
        case "numericfield":
            if (question.metricMultiple) {
                const answerLines = (Array.isArray(answerValue) ? answerValue : []) as AnswerLine[];

                const addAnswerLine = () => {
                    onChange([...answerLines, createAnswerLine()])
                }

                if (answerLines.length === 0) {
                    addAnswerLine();
                }

                return (
                    <div>
                        <div>
                            {answerLines.map((answerLine, i) => (
                                <AnswerLineForm key={answerLine.identity}
                                                question={question}
                                                answerLine={answerLine}
                                                onChange={value => onChange(
                                                    arrayReplace(answerLines, value, 'identity', answerLine.identity)
                                                )}
                                                onRemove={() => onChange(arraySplice(answerLines, i, 1))}
                                />
                            ))}
                        </div>
                        <button onClick={() => addAnswerLine()} className="btn btn-link p-0">{t('New entry')}</button>
                    </div>
                );
            }

            return <NumberInput
                id={"question" + question.id}
                maxWidth={400}
                value={typeof answerValue === "number" ? answerValue : undefined}
                onChange={(value) => {
                    onChange(typeof value !== "number" ? null : value);
                }}
                min={question.valueMin}
                max={question.valueMax}
                unit={question.unit}
            />
        case "textfield":
            return <input type="text" className="form-control"
                          id={"question" + question.id}
                          value={typeof answerValue === 'string' ? answerValue : ''}
                          onChange={e => onChange(e.target.value)}
            />;

        case "textarea":
            return <textarea className="form-control" id={"question" + question.id}
                             value={typeof answerValue === 'string' ? answerValue : ''}
                             onChange={e => onChange(e.target.value)}
            />;
        case "checkbox":
            const ids = Array.isArray(answerValue) ?
                answerValue.filter(value => typeof value === 'number') : [];

            return (
                <div>
                    {question.options.map(option => (
                        <div className="form-check" key={option.id}>
                            <input className="form-check-input" type="checkbox" id={"option" + option.id}
                                   checked={ids.includes(option.id)}
                                   onChange={e => onChange(e.target.checked ? [...ids, option.id] : pull([...ids], option.id))}
                            />
                            <label className="form-check-label" htmlFor={"option" + option.id}>
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            );

        case "radio":
            return (
                <div>
                    {question.options.map(option => (
                        <div className="form-check" key={option.id}>
                            <input className="form-check-input" type="radio" id={"option" + option.id}
                                   checked={answerValue === option.id}
                                   onChange={() => onChange(option.id)}
                            />
                            <label className="form-check-label" htmlFor={"option" + option.id}>
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            )
        case "column":
            const answerRows = (Array.isArray(answerValue) ? answerValue : []) as AnswerRow[];

            const addAnswerRow = (columns: AnswerColumn[] = []) => {
                onChange([...answerRows, createAnswerRow(columns)])
            }

            if (answerRows.length === 0) {
                addAnswerRow();
            }

            return (
                <div>
                    <div>
                        {answerRows.map((answerRow, i) => (
                            <AnswerRowForm key={answerRow.identity}
                                           question={question}
                                           answerRow={answerRow}
                                           onChange={value => onChange(
                                               arrayReplace(answerRows, value, 'identity', answerRow.identity)
                                           )}
                                           onRemove={() => onChange(arraySplice(answerRows, i, 1))}
                            />
                        ))}
                    </div>
                    <button onClick={() => addAnswerRow()} className="btn btn-link p-0">{t('New entry')}</button>
                </div>
            );


        default:
            return <div>{question.answerType}</div>
    }
}

export default React.memo(AnswerValueForm);